export const DE_labels = [
  {
    label: "LABEL_GESTISCI_PRENOTAZIONE",
    text: "Buchung verwalten",
  },
  {
    label: "LABEL_ROOM_NOT",
    text: "Nicht ",
  },
  {
    label: "LABEL_ROOM_REFUNDABLE",
    text: "Erstattungsfähig",
  },
  {
    label: "LABEL_SEARCH_CAMERE",
    text: "Zimmer",
  },
  {
    label: "LABEL_SEARCH_SELECT",
    text: "Wählen",
  },
  {
    label: "LABEL_SEARCH_TYPE",
    text: "Typologie",
  },
  {
    label: "LABEL_SEARCH_ALL",
    text: "Alle",
  },
  {
    label: "LABEL_SEARCH_ADULTI",
    text: "Erwachsene",
  },
  {
    label: "LABEL_SEARCH_BAMBINI",
    text: "Kinder",
  },
  {
    label: "LABEL_SEARCH_ROOM_PLACEHOLDER",
    text: "Zimmer",
  },
  {
    label: "LABEL_SEARCH_BUTTON",
    text: "Verfügbarkeit prüfen",
  },
  {
    label: "LABEL_SEARCH_BUTTON_SEARCHING",
    text: "Ich suche ...",
  },
  {
    label: "LABEL_SEARCH_COUPON_CODE",
    text: "Ich habe einen Rabattcode",
  },
  {
    label: "LABEL_SEARCH_PLACEHOLDER_COUPON",
    text: "Geben Sie hier Ihren Code ein",
  },
  {
    label: "LABEL_CALENDAR_ARRIVO",
    text: "Ankunft",
  },
  {
    label: "LABEL_CALENDAR_PARTENZA",
    text: "Abreise",
  },
  {
    label: "LABEL_CALENDAR_NOTTI",
    text: "Nächte",
  },
  {
    label: "LABEL_ROOM_DESCR_SHOW_MORE",
    text: "... Mehr lesen",
  },
  {
    label: "LABEL_ROOM_DESCR_SHOW_LESS",
    text: "... Weniger anzeigen",
  },
  {
    label: "LABEL_ROOM_GUESTS",
    text: "Gäste",
  },
  {
    label: "LABEL_ROOM_ADDED_CART",
    text: "Ihr Zimmer wurde dem Warenkorb hinzugefügt",
  },
  {
    label: "LABEL_FROM_DAY",
    text: "ab dem Tag",
  },
  {
    label: "LABEL_FROM_DAY_SHORT",
    text: "Ab",
  },
  {
    label: "LABEL_TO_DAY",
    text: "bis",
  },
  {
    label: "LABEL_ROOM_REMOVED",
    text: "ist nicht mehr verfügbar und wurde entfernt",
  },
  {
    label: "LABEL_REMOVE_FROM_CART",
    text: "Aus dem Warenkorb entfernen",
  },
  {
    label: "LABEL_OPS_EMPTY",
    text: "Oops.. hier ist leer",
  },
  {
    label: "LABEL_ADD_ROOM_PLACEHOLDER",
    text: "Fügen Sie ein Zimmer hinzu, indem Sie das für Sie am besten geeignete suchen...",
  },
  {
    label: "LABEL_TOTAL",
    text: "Gesamt",
  },
  {
    label: "LABEL_GOTO_PAYMENT",
    text: "Zur Zahlung",
  },
  {
    label: "LABEL_NO_ROOMS_FOUND",
    text: "Es tut uns leid, aber wir haben keine Zimmer für die gesuchten Daten verfügbar.",
  },
  {
    label: "LABEL_NO_ROOMS_GG_MIN_1",
    text: "Es tut uns leid, für die ausgewählten Daten erfordert die Unterkunft einen Mindestaufenthalt von",
  },
  {
    label: "LABEL_NO_ROOMS_GG_MIN_2",
    text: " Nächten.",
  },
  {
    label: "LABEL_NO_ROOMS_LAST_MINUTE",
    text: "Es tut uns leid, wir akzeptieren keine Last-Minute-Buchungen. Buchungen für heute sind geschlossen.",
  },
  {
    label: "LABEL_CONTACT_US",
    text: "Bitte versuchen Sie es erneut oder kontaktieren Sie uns unter folgenden Kontakten:",
  },
  {
    label: "LABEL_SERVICES",
    text: "Verfügbare Dienstleistungen",
  },
  {
    label: "LABEL_NO_SERVICE",
    text: "Keine Dienstleistungen verfügbar",
  },
  {
    label: "LABEL_TAX_STAY",
    text: "Kurtaxe",
  },
  {
    label: "LABEL_CURRENCY_LONG",
    text: "EUR",
  },
  {
    label: "LABEL_STAY_TAX_DESCR",
    text: "pro Nacht pro Gast vor Ort zu bezahlen.",
  },
  {
    label: "LABEL_NO_TAX_TO_PAY",
    text: "Keine Kurtaxe zu zahlen.",
  },
  {
    label: "LABEL_DESCRIPTION",
    text: "Beschreibung",
  },
  {
    label: "CIN_CODE",
    text: "CIN-Code:",
  },
  {
    label: "LABEL_BOOKING_TERMS",
    text: "Buchungsbedingungen und Stornierungsrichtlinien",
  },
  {
    label: "LABLE_OCCUPAZIONE_INFERIORE_TITLE",
    text: "Belegung geringer als angefordert",
  },
  {
    label: "LABLE_OCCUPAZIONE_INFERIORE_DESCR",
    text: "Die Belegung der ausgewählten Zimmer ist geringer als die angeforderte Personenzahl. Trotzdem mit der Zahlung fortfahren?",
  },
  {
    label: "LABLE_OCCUPAZIONE_INFERIORE_YES",
    text: "Ja, fortfahren",
  },
  {
    label: "LABEL_FIXED_COST",
    text: "Fixkosten",
  },
  {
    label: "LABEL_FIXED_COST_INCLUDED",
    text: " (inklusive Fixkosten)",
  },
  {
    label: "LABEL_TOTAL_BOOKING_ROOM",
    text: "Gesamtaufenthalt",
  },
  {
    label: "TOOLTIP_ROOM_NOT_REFAUNDABLE",
    text: "Dieser Tarif bietet einen günstigen Preis, aber keine Rückerstattung bei Stornierung, Änderung oder Nichterscheinen.",
  },
  {
    label: "LABEL_PRICE",
    text: "Preis",
  },
  {
    label: "LABEL_ADD_TO_CART",
    text: "Zum Warenkorb hinzufügen",
  },
  {
    label: "LABEL_BOOK_NOW",
    text: "Jetzt buchen!",
  },
  {
    label: "LABEL_CHECK_ROOM_CART",
    text: "Möchten Sie die Zimmer im Warenkorb behalten?",
  },
  {
    label: "LABEL_CHECK_ROOM_CART_YES",
    text: "Ja, Zimmer speichern",
  },
  {
    label: "LABEL_CHECK_ROOM_CART_NO",
    text: "Nein",
  },
  {
    label: "LABEL_MINIMO_NOTTI",
    text: "Mindestanzahl der Nächte erforderlich",
  },
  {
    label: "LABEL_RESTRIZIONI",
    text: "An- und Abreise nicht erlaubt",
  },
  {
    label: "LABEL_PERMESSO_ARRIVO",
    text: "Nur Ankunft erlaubt",
  },
  {
    label: "LABEL_PERMESSO_PARTENZA",
    text: "Nur Abreise erlaubt",
  },
  {
    label: "LABEL_RIEPILOGO",
    text: "Buchungsübersicht",
  },
  {
    label: "ROOM_SERVICES",
    text: "Verfügbare Dienstleistungen:",
  },
  {
    label: "NO_SERVICES",
    text: "Keine Dienstleistungen verfügbar",
  },
  {
    label: "PRICE_FROM",
    text: "Ab",
  },
  {
    label: "BUTTON_ROOM_INFO_BOOK",
    text: "Info und buchen",
  },
  {
    label: "LABEL_ROOM_REMOVED_APARTMENT",
    text: "Das Zimmer / Apartment",
  },
  {
    label: "LABEL_RETURN_TO_SEARCH",
    text: "Zurück zur Suche",
  },
  {
    label: "LABEL_YEARS",
    text: "Jahre",
  },
  {
    label: "LABEL_UNAVAILABLE",
    text: "Nicht mehr verfügbar",
  },
  {
    label: "LABEL_ROOM_SEARCHED_FOR",
    text: "Das gesuchte Zimmer am",
  },
  {
    label: "LABEL_NO_MORE_AVAILABLE",
    text: "ist nicht mehr verfügbar, bitte führen Sie eine neue Suche durch.",
  },
  {
    label: "LABEL_PRICE_BOOKING",
    text: "Preis des Aufenthalts:",
  },
  {
    label: "LABEL_DISCOUNT_CODE",
    text: "Rabattcode",
  },
  {
    label: "LABEL_DISCOUNT_AMOUNT",
    text: "Angewendeter Rabatt",
  },
  {
    label: "LABEL_YOUR_CART",
    text: "Ihr Warenkorb",
  },
  {
    label: "LABEL_TAX_PAY_EXCLUDED",
    text: "Die Kurtaxe ist nicht im Preis inbegriffen",
  },
  {
    label: "SUBTITLE_EXTRAS",
    text: "Machen Sie Ihren Aufenthalt unvergesslich, indem Sie Ihre Lieblingserlebnisse auswählen",
  },
  {
    label: "LABEL_EXTRA_TOTAL",
    text: "Gesamtbetrag einschließlich Zusatzleistungen",
  },
  {
    label: "LABEL_NO_ROOM_PAY",
    text: "Es ist nicht möglich, den Kauf fortzusetzen, da keine Zimmer oder Wohneinheiten für die Buchung verfügbar sind.",
  },
  {
    label: "LABEL_NO_SEARCH_COMBINATIONS",
    text: "Es wurden keine Kombinationen für die durchgeführte Suche gefunden. Bitte geben Sie im linken Feld die Aufenthaltsdaten, die Anzahl der Personen und die gewünschten Zimmer ein.",
  },
  {
    label: "LABEL_PAYMENT_INSERT_YOUR_DATA",
    text: "Geben Sie Ihre Daten ein",
  },
  {
    label: "LABEL_PAYMENT_NO_MANDATORY",
    text: "Mit einem Sternchen gekennzeichnete Felder sind Pflichtfelder",
  },
  {
    label: "LABEL_PAYMENT_FORM_NAME",
    text: "Name *",
  },
  {
    label: "LABEL_PAYMENT_FORM_ERROR_NAME",
    text: "Bitte geben Sie einen gültigen Namen ein",
  },
  {
    label: "LABEL_PAYMENT_FORM_ERROR_SURNAME",
    text: "Bitte geben Sie einen gültigen Nachnamen ein",
  },
  {
    label: "LABEL_PAYMENT_FORM_ERROR_EMAIL",
    text: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
  },
  {
    label: "LABEL_PAYMENT_FORM_ERROR_PHONE",
    text: "Bitte geben Sie eine gültige Telefonnummer ein",
  },
  {
    label: "LABEL_PAYMENT_FORM_SURNAME",
    text: "Nachname *",
  },
  {
    label: "LABEL_PAYMENT_FORM_EMAIL",
    text: "E-Mail *",
  },
  {
    label: "LABEL_PAYMENT_FORM_CONFIRM_EMAIL",
    text: "E-Mail bestätigen *",
  },
  {
    label: "LABEL_PAYMENT_FORM_PHONE",
    text: "Mobilnummer *",
  },
  {
    label: "LABEL_PAYMENT_CONFIRM_FORM_PHONE",
    text: "Mobilnummer bestätigen *",
  },
  {
    label: "LABEL_PAYMENT_FORM_COUNTRY",
    text: "Land",
  },
  {
    label: "LABEL_PAYMENT_FORM_CITY",
    text: "Stadt",
  },
  {
    label: "LABEL_PAYMENT_FORM_ZIP_CODE",
    text: "PLZ",
  },
  {
    label: "LABEL_PAYMENT_FORM_ADDRESS",
    text: "Adresse",
  },
  {
    label: "LABEL_PAYMENT_FORM_NOTES",
    text: "Notizen / Zusätzliche Informationen (optional)",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_METHODS",
    text: "Zahlungsmethoden",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_METHODS_1",
    text: "Wählen Sie eine Zahlungsmethode für die Buchung aus",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_METHODS_2",
    text: "Zahlungsart",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_CARD",
    text: "Kreditkarte",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_TRANSFER",
    text: "Banküberweisung",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_CARD_SUBTITLE_1",
    text: "Kreditkarte als Garantie - sichere Seite",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_CARD_SUBTITLE_2",
    text: "Ihre Kreditkartendaten werden als Garantie für die Buchung und für eventuelle Extras angefordert.",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_CARD_SUBTITLE_3",
    text: "behält sich das Recht vor, vor Ihrer Ankunft einen Betrag in Höhe der ersten Nacht vorübergehend einzubehalten, um die Kreditkarte zu autorisieren. Wir empfehlen, anstelle einer Debit- oder Prepaid-Karte eine Kreditkarte zu verwenden, um die Bearbeitungszeiten für die Freigabe der eventuellen Vorautorisierung zu verkürzen.",
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_1",
    text: "Ich habe die Datenschutzbedingungen gelesen und akzeptiere sie, wie angegeben",
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_2",
    text: "in der Datenschutzerklärung",
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_3",
    text: "Mit der Bestätigung dieser Buchung akzeptiere ich auch die",
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_4",
    text: "allgemeinen Geschäftsbedingungen",
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_5",
    text: " und die oben für jede Wohneinheit aufgeführten Buchungsbedingungen.",
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_6",
    text: "Ich möchte Informationen über Sonderangebote und Neuigkeiten erhalten.",
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_7",
    text: "Ich habe die Datenschutzbedingungen gelesen und akzeptiere sie.",
  },
  {
    label: "LABEL_PAYMENT_FORM_BUTTON_1",
    text: "Buchung bestätigen",
  },
  {
    label: "LABEL_PAYMENT_FORM_BUTTON_2",
    text: "Online bezahlen",
  },
  {
    label: "LABEL_PAYMENT_FORM_BUTTON_SUBTITLE_1",
    text: "Es gibt noch einen Schritt vor Abschluss des Vorgangs",
  },
  {
    label: "LABEL_PAYMENT_FORM_ONE_MOMENT",
    text: "Es dauert nur einen Moment",
  },
  {
    label: "BT_TITLE",
    text: "Zahlungsmethode: Banküberweisung",
  },
  {
    label: "BT_SUBTITLE",
    text: "Anweisungen:",
  },
  {
    label: "BT_TEXT1",
    text: "Bitte führen Sie die Zahlung innerhalb der nächsten",
  },
  {
    label: "BT_TEXT1_5",
    text: " Tage durch. Sobald wir die Überweisung erhalten, senden wir Ihnen eine Bestätigung per E-Mail mit den Details Ihres Kaufs.",
  },
  {
    label: "BT_TEXT2",
    text: "Nachdem Sie die Überweisung durchgeführt haben, denken Sie daran, eine Kopie der Quittung zu speichern und hier hochzuladen.",
  },
  {
    label: "BT_TEXT3",
    text: "Wichtig",
  },
  {
    label: "BT_TEXT4",
    text: "Wir überprüfen die Gutschriften nicht täglich, daher vergessen Sie nicht, die Quittung der Überweisung hier hochzuladen, sonst ist die Buchung nicht gültig.",
  },
  {
    label: "BT_TEXT5",
    text: "* Bitte geben Sie die Angaben korrekt ein, insbesondere den Verwendungszweck, um die Bearbeitung Ihrer Bestellung zu erleichtern",
  },
  {
    label: "BT_FORM_1",
    text: "IBAN:",
  },
  {
    label: "BT_FORM_2",
    text: "Verwendungszweck:",
  },
  {
    label: "BT_FORM_3",
    text: "Begünstigter:",
  },
  {
    label: "BT_FORM_4",
    text: "Betrag:",
  },
  {
    label: "BT_FORM_5",
    text: "Überweisung hochladen und senden",
  },
  {
    label: "BT_PAY_1",
    text: "Dateien hier ablegen!",
  },
  {
    label: "BT_PAY_2",
    text: "Ziehen Sie Ihre Quittung hierher!",
  },
  {
    label: "BT_PAY_3",
    text: "Oder klicken Sie hier, um die Dateien auszuwählen",
  },
  {
    label: "BT_PAY_4",
    text: "Ihre Datei ist zum Hochladen bereit.",
  },
  {
    label: "BT_PAY_5",
    text: 'Drücken Sie auf "Überweisung hochladen und senden", um die Buchung zu bestätigen.',
  },
  {
    label: "CARD_FORM_1",
    text: "Kartennummer",
  },
  {
    label: "CARD_FORM_2",
    text: "Name des Karteninhabers",
  },
  {
    label: "CARD_FORM_3",
    text: "Ablaufdatum",
  },
  {
    label: "CARD_FORM_4",
    text: "Monat",
  },
  {
    label: "CARD_FORM_5",
    text: "Jahr",
  },
  {
    label: "CARD_FORM_6",
    text: "Buchung bestätigen",
  },
  {
    label: "CARD_FORM_7",
    text: "VOLLSTÄNDIGER NAME",
  },
  {
    label: "CARD_FORM_8",
    text: "Ablaufdatum",
  },
  {
    label: "SUCCESS_THANK_YOU",
    text: "Vielen Dank",
  },
  {
    label: "SUCCESS_THANK_YOU_1",
    text: "Ihre Buchung ist bestätigt",
  },
  {
    label: "SUCCESS_THANK_YOU_2",
    text: "Eine E-Mail mit allen Details wurde an Ihre Adresse gesendet",
  },
  {
    label: "SUCCESS_THANK_YOU_3",
    text: "Erwartet Sie am",
  },
  {
    label: "SUCCESS_THANK_YOU_4",
    text: "Die Zahlung",
  },
  {
    label: "SUCCESS_THANK_YOU_5",
    text: "wird bearbeitet durch",
  },
  {
    label: "SUCCESS_THANK_YOU_6",
    text: "Ändern Sie Ihre Buchung oder stellen Sie der Unterkunft eine Frage mit nur wenigen Klicks.",
  },
  {
    label: "SUCCESS_THANK_YOU_7",
    text: "Buchungsdetails",
  },
  {
    label: "SUCCESS_THANK_YOU_8",
    text: "Ihre Buchung",
  },
  {
    label: "SUCCESS_THANK_YOU_9",
    text: "Buchungscode",
  },
  {
    label: "SUCCESS_THANK_YOU_10",
    text: "Pin für Änderungen",
  },
  {
    label: "SUCCESS_THANK_YOU_11",
    text: "Auf den Namen gebucht",
  },
  {
    label: "SUCCESS_THANK_YOU_12",
    text: "Extras Ihres Aufenthalts",
  },
  {
    label: "SUCCESS_THANK_YOU_13",
    text: "Menge:",
  },
  {
    label: "SUCCESS_THANK_YOU_14",
    text: "Steuern",
  },
  {
    label: "SUCCESS_THANK_YOU_15",
    text: "Vor Ort zu zahlen",
  },
  {
    label: "SUCCESS_THANK_YOU_16",
    text: "Das von Ihnen gewählte Paket",
  },
  {
    label: "SUCCESS_THANK_YOU_17",
    text: "Sie haben einen Rabattcode eingegeben",
  },
  {
    label: "PAYMENT_LOADING_TEXT_1",
    text: "Wir kontaktieren die Unterkunft ...",
  },
  {
    label: "PAYMENT_LOADING_TEXT_2",
    text: "Wir speichern Ihre Buchung ...",
  },
  {
    label: "PAYMENT_LOADING_TEXT_3",
    text: "Wir bestätigen den Status ...",
  },
  {
    label: "ERROR_TITLE",
    text: "Oops.. etwas ist schiefgelaufen",
  },
  {
    label: "ERROR_CODE_5_TEXT",
    text: "Leider wurden ein oder mehrere Zimmer Ihrer Lösung bereits gebucht. Sie werden zu einer neuen Suche weitergeleitet.",
  },
  {
    label: "ERROR_CODE_GENERIC_TEXT",
    text: "Die Buchung konnte nicht erstellt werden. Wir empfehlen, die Unterkunft zu kontaktieren.",
  },
  {
    label: "LABEL_ERROR_ALERT_1",
    text: "Oops.. Das Zimmer ist bereits belegt",
  },
  {
    label: "LABEL_BODY_ERROR_ALERT_1",
    text: "Es scheint, dass Sie bereits ein gleiches Zimmer buchen, aber die Unterkunft hat keine Verfügbarkeit mehr für diesen Typ. Bitte versuchen Sie eine neue Suche.",
  },
  {
    label: "LABEL_TITLE_ROOM_ADDED_CART",
    text: "Das Zimmer ist gespeichert!",
  },
  {
    label: "LABEL_PRIVACY_POLICY",
    text: "Datenschutzbedingungen ( PRIVACY POLICY )",
  },
  {
    label: "LABEL_BOOKING_TERMS",
    text: "Buchungsbedingungen ( BOOKING TERMS )",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART1",
    text: "Gemäß Artikel 13 der Verordnung (EU) 2016/679 über den Schutz personenbezogener Daten informieren wir Sie, dass Ihre von Ihnen freiwillig bereitgestellten personenbezogenen Daten von F451 S.R.L., im Folgenden der Datenverantwortliche, hauptsächlich mit IT-Mitteln verarbeitet werden, um auf Ihre Anfrage zur Anmeldung zu unserem automatisierten Newsletter zu reagieren, der es Ihnen ermöglicht, über die von uns angebotenen Dienstleistungen und unsere Werbeaktivitäten auf dem Laufenden zu bleiben, sowie uns Ihre Zufriedenheit mit den von Ihnen genutzten Dienstleistungen mitzuteilen, Marktuntersuchungen durchzuführen und Ihnen Werbematerial zu senden.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART2",
    text: "Wir erinnern Sie daran, dass Sie jederzeit die Möglichkeit haben, Ihre Zustimmung zu dieser Anmeldung zu widerrufen.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART3",
    text: "Die Bereitstellung Ihrer personenbezogenen Daten ist freiwillig. Das Fehlen dieser Daten verhindert jedoch, dass wir Ihrer Anfrage nachkommen und Sie daher für unseren Newsletter anmelden können.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART4",
    text: "Wenn Sie uns Ihre Daten zur Verfügung stellen, informieren wir Sie, dass diese ausschließlich von autorisiertem Personal des Verantwortlichen verarbeitet werden und nur so lange aufbewahrt werden, wie es erforderlich ist, um die oben genannten Zwecke zu erreichen.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART5",
    text: "Ihre Daten werden unter keinen Umständen verbreitet, können jedoch an Dritte weitergegeben werden, denen sich der Verantwortliche ausschließlich und ausschließlich zur Gewährleistung der ordnungsgemäßen Verfolgung der oben genannten Zwecke anvertraut.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART6",
    text: "Wir erinnern Sie daran, dass Sie die folgenden Rechte garantiert haben: Recht auf Auskunft, Recht auf Berichtigung, Recht auf Löschung, Recht auf Einschränkung, Recht auf Übertragbarkeit, Recht auf Widerruf der Einwilligung, Recht auf Beschwerde, Recht auf Widerspruch, Recht, keiner ausschließlich auf automatisierter Verarbeitung, einschließlich Profiling, beruhenden Entscheidung unterworfen zu werden, außer in den in der Verordnung vorgesehenen Fällen. Um diese Rechte auszuüben, können Sie sich schriftlich an den Datenverantwortlichen F451 S.R.L. - Via Giuseppe Parini 13 oder per E-Mail an sviluppo@idaitalia.com wenden. Unbeschadet anderer administrativer oder gerichtlicher Rechtsbehelfe haben Sie das Recht, bei einer Aufsichtsbehörde Beschwerde einzureichen, wenn Sie der Ansicht sind, dass die Verarbeitung, die Sie betrifft, gegen die Verordnung verstößt.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_TITLE",
    text: "Newsletter und Marketing",
  },
  {
    label: "ROOM_TREATMENT",
    text: "Verpflegung:",
  },
  {
    label: "LABEL_SHOW_MORE",
    text: "Mehr anzeigen",
  },
  {
    label: "LABEL_SAVE_ROOM",
    text: "Zimmer speichern",
  },
  {
    label: "TITLE_CANCELLATION_POLICY_NOREFUNDABLE",
    text: "Stornierungsrichtlinie",
  },
  {
    label: "LABEL_CANCELLATION_POLICY_NOREFUNDABLE",
    text: "Gesamtkosten bei Buchung ohne Möglichkeit der Änderung oder Stornierung.",
  },
  {
    label: "LABEL_NOREFUNDABLE",
    text: "Nicht erstattbar",
  },
  {
    label: "BUTTON_NOREFUNDABLE",
    text: "Nicht erstattbar",
  },
  {
    label: "PACKET_STARTING",
    text: "Ab",
  },
  {
    label: "PACKET_FOR",
    text: "für",
  },
  {
    label: "PACKET_NIGHTS",
    text: "Nächte.",
  },
  {
    label: "PACKET_SHOW",
    text: "Alle Pakete anzeigen",
  },
  {
    label: "PACKET_CHOOSE",
    text: "Paket wählen",
  },
  {
    label: "PACKET_BACK",
    text: "Zurück",
  },
  {
    label: "PACKET_CHOOSE_DATE",
    text: "Datum auswählen:",
  },
  {
    label: "PACKET_VALID_PERIOD",
    text: "Sie haben einen gültigen Zeitraum gewählt",
  },
  {
    label: "PACKET_WRONG_PERIOD",
    text: "Sie haben einen ungültigen Zeitraum gewählt",
  },
  {
    label: "PACKET_SELECT_ROOM",
    text: "Wählen Sie ein Zimmer",
  },
];
