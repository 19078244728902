export const EN_labels = [
  {
    label: "LABEL_GESTISCI_PRENOTAZIONE",
    text: "Manage Booking",
  },
  {
    label: "LABEL_ROOM_NOT",
    text: "Not ",
  },
  {
    label: "LABEL_ROOM_REFUNDABLE",
    text: "Refundable",
  },
  {
    label: "LABEL_SEARCH_CAMERE",
    text: "Rooms",
  },
  {
    label: "LABEL_SEARCH_SELECT",
    text: "Select",
  },
  {
    label: "LABEL_SEARCH_TYPE",
    text: "Typology",
  },
  {
    label: "LABEL_SEARCH_ALL",
    text: "All",
  },
  {
    label: "LABEL_SEARCH_ADULTI",
    text: "Adults",
  },
  {
    label: "LABEL_SEARCH_BAMBINI",
    text: "Children",
  },
  {
    label: "LABEL_SEARCH_ROOM_PLACEHOLDER",
    text: "Room",
  },
  {
    label: "LABEL_SEARCH_BUTTON",
    text: "Check Availability",
  },
  {
    label: "LABEL_SEARCH_BUTTON_SEARCHING",
    text: "Searching ...",
  },
  {
    label: "LABEL_SEARCH_COUPON_CODE",
    text: "I have a discount code",
  },
  {
    label: "LABEL_SEARCH_PLACEHOLDER_COUPON",
    text: "Enter your code here",
  },
  {
    label: "LABEL_CALENDAR_ARRIVO",
    text: "Arrival",
  },
  {
    label: "LABEL_CALENDAR_PARTENZA",
    text: "Departure",
  },
  {
    label: "LABEL_CALENDAR_NOTTI",
    text: "Nights",
  },
  {
    label: "LABEL_ROOM_DESCR_SHOW_MORE",
    text: "... Read more",
  },
  {
    label: "LABEL_ROOM_DESCR_SHOW_LESS",
    text: "... Show less",
  },
  {
    label: "LABEL_ROOM_GUESTS",
    text: "Guests",
  },
  {
    label: "LABEL_ROOM_ADDED_CART",
    text: "Your room has been added to the cart",
  },
  {
    label: "LABEL_FROM_DAY",
    text: "from the day",
  },
  {
    label: "LABEL_FROM_DAY_SHORT",
    text: "From",
  },
  {
    label: "LABEL_TO_DAY",
    text: "to",
  },
  {
    label: "LABEL_ROOM_REMOVED",
    text: "is no longer available and has been removed",
  },
  {
    label: "LABEL_REMOVE_FROM_CART",
    text: "Remove from cart",
  },
  {
    label: "LABEL_OPS_EMPTY",
    text: "Oops.. it's empty here",
  },
  {
    label: "LABEL_ADD_ROOM_PLACEHOLDER",
    text: "Add a room by searching for the one that suits you best...",
  },
  {
    label: "LABEL_TOTAL",
    text: "Total",
  },
  {
    label: "LABEL_GOTO_PAYMENT",
    text: "Go to payment",
  },
  {
    label: "LABEL_NO_ROOMS_FOUND",
    text: "Sorry, we have no rooms available for the searched dates.",
  },
  {
    label: "LABEL_NO_ROOMS_GG_MIN_1",
    text: "Sorry, for the selected dates the property requires a minimum stay of",
  },
  {
    label: "LABEL_NO_ROOMS_GG_MIN_2",
    text: " nights.",
  },
  {
    label: "LABEL_NO_ROOMS_LAST_MINUTE",
    text: "Sorry, we do not accept last-minute bookings. Reservations for today are closed.",
  },
  {
    label: "LABEL_CONTACT_US",
    text: "Please try again or contact us at the following details:",
  },
  {
    label: "LABEL_SERVICES",
    text: "Available Services",
  },
  {
    label: "LABEL_NO_SERVICE",
    text: "No Services Available",
  },
  {
    label: "LABEL_TAX_STAY",
    text: "Tourist Tax",
  },
  {
    label: "LABEL_CURRENCY_LONG",
    text: "EUR",
  },
  {
    label: "LABEL_STAY_TAX_DESCR",
    text: "per night per guest to be paid on site.",
  },
  {
    label: "LABEL_NO_TAX_TO_PAY",
    text: "No tourist tax to pay.",
  },
  {
    label: "LABEL_DESCRIPTION",
    text: "Description",
  },
  {
    label: "CIN_CODE",
    text: "CIN Code:",
  },
  {
    label: "LABEL_BOOKING_TERMS",
    text: "Booking Terms and Cancellation Policies",
  },
  {
    label: "LABLE_OCCUPAZIONE_INFERIORE_TITLE",
    text: "Occupation lower than requested",
  },
  {
    label: "LABLE_OCCUPAZIONE_INFERIORE_DESCR",
    text: "The occupancy of the selected rooms is lower than the number of people requested. Proceed with payment anyway?",
  },
  {
    label: "LABLE_OCCUPAZIONE_INFERIORE_YES",
    text: "Yes, proceed",
  },
  {
    label: "LABEL_FIXED_COST",
    text: "Fixed Costs",
  },
  {
    label: "LABEL_FIXED_COST_INCLUDED",
    text: " (including fixed costs)",
  },
  {
    label: "LABEL_TOTAL_BOOKING_ROOM",
    text: "Total Stay",
  },
  {
    label: "TOOLTIP_ROOM_NOT_REFAUNDABLE",
    text: "This rate offers an advantageous price but does not provide any refund in case of cancellation, modification, or no-show.",
  },
  {
    label: "LABEL_PRICE",
    text: "Price",
  },
  {
    label: "LABEL_ADD_TO_CART",
    text: "Add to cart",
  },
  {
    label: "LABEL_BOOK_NOW",
    text: "Book Now!",
  },
  {
    label: "LABEL_CHECK_ROOM_CART",
    text: "Do you want to keep the rooms in the cart?",
  },
  {
    label: "LABEL_CHECK_ROOM_CART_YES",
    text: "Yes, save the rooms",
  },
  {
    label: "LABEL_CHECK_ROOM_CART_NO",
    text: "No",
  },
  {
    label: "LABEL_MINIMO_NOTTI",
    text: "Minimum number of nights required",
  },
  {
    label: "LABEL_RESTRIZIONI",
    text: "Arrival and departure not allowed",
  },
  {
    label: "LABEL_PERMESSO_ARRIVO",
    text: "Only arrival allowed",
  },
  {
    label: "LABEL_PERMESSO_PARTENZA",
    text: "Only departure allowed",
  },
  {
    label: "LABEL_RIEPILOGO",
    text: "Booking Summary",
  },
  {
    label: "ROOM_SERVICES",
    text: "Available Services:",
  },
  {
    label: "NO_SERVICES",
    text: "No services available",
  },
  {
    label: "PRICE_FROM",
    text: "Starting from",
  },
  {
    label: "BUTTON_ROOM_INFO_BOOK",
    text: "Info and book",
  },
  {
    label: "LABEL_ROOM_REMOVED_APARTMENT",
    text: "The room/apartment",
  },
  {
    label: "LABEL_RETURN_TO_SEARCH",
    text: "Return to search",
  },
  {
    label: "LABEL_YEARS",
    text: "years",
  },
  {
    label: "LABEL_UNAVAILABLE",
    text: "No longer available",
  },
  {
    label: "LABEL_ROOM_SEARCHED_FOR",
    text: "The room searched for on the date from",
  },
  {
    label: "LABEL_NO_MORE_AVAILABLE",
    text: "is no longer available, please perform a new search.",
  },
  {
    label: "LABEL_PRICE_BOOKING",
    text: "Booking Price:",
  },
  {
    label: "LABEL_DISCOUNT_CODE",
    text: "Discount code",
  },
  {
    label: "LABEL_DISCOUNT_AMOUNT",
    text: "Discount applied",
  },
  {
    label: "LABEL_YOUR_CART",
    text: "Your cart",
  },
  {
    label: "LABEL_TAX_PAY_EXCLUDED",
    text: "The tourist tax is not included in the price",
  },
  {
    label: "SUBTITLE_EXTRAS",
    text: "Make your stay unforgettable by selecting your favorite experiences",
  },
  {
    label: "LABEL_EXTRA_TOTAL",
    text: "Total including additional services",
  },
  {
    label: "LABEL_NO_ROOM_PAY",
    text: "Unable to proceed with the purchase as there are no rooms or units available for booking.",
  },
  {
    label: "LABEL_NO_SEARCH_COMBINATIONS",
    text: "No combinations found for the search performed. Please enter the stay dates, the number of people, and the rooms required in the box on the left.",
  },
  {
    label: "LABEL_PAYMENT_INSERT_YOUR_DATA",
    text: "Enter your details",
  },
  {
    label: "LABEL_PAYMENT_NO_MANDATORY",
    text: "Fields marked with an asterisk are mandatory",
  },
  {
    label: "LABEL_PAYMENT_FORM_NAME",
    text: "First Name *",
  },
  {
    label: "LABEL_PAYMENT_FORM_ERROR_NAME",
    text: "Please enter a valid name",
  },
  {
    label: "LABEL_PAYMENT_FORM_ERROR_SURNAME",
    text: "Please enter a valid surname",
  },
  {
    label: "LABEL_PAYMENT_FORM_ERROR_EMAIL",
    text: "Please enter a valid email",
  },
  {
    label: "LABEL_PAYMENT_FORM_ERROR_PHONE",
    text: "Please enter a valid phone number",
  },
  {
    label: "LABEL_PAYMENT_FORM_SURNAME",
    text: "Last Name *",
  },
  {
    label: "LABEL_PAYMENT_FORM_EMAIL",
    text: "Email *",
  },
  {
    label: "LABEL_PAYMENT_FORM_CONFIRM_EMAIL",
    text: "Confirm Email *",
  },
  {
    label: "LABEL_PAYMENT_FORM_PHONE",
    text: "Mobile Number *",
  },
  {
    label: "LABEL_PAYMENT_CONFIRM_FORM_PHONE",
    text: "Confirm Mobile Number *",
  },
  {
    label: "LABEL_PAYMENT_FORM_COUNTRY",
    text: "Country",
  },
  {
    label: "LABEL_PAYMENT_FORM_CITY",
    text: "City",
  },
  {
    label: "LABEL_PAYMENT_FORM_ZIP_CODE",
    text: "ZIP Code",
  },
  {
    label: "LABEL_PAYMENT_FORM_ADDRESS",
    text: "Address",
  },
  {
    label: "LABEL_PAYMENT_FORM_NOTES",
    text: "Notes / Additional information (optional)",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_METHODS",
    text: "Payment methods",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_METHODS_1",
    text: "Select a payment method for the booking",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_METHODS_2",
    text: "Payment type",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_CARD",
    text: "Credit Card",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_TRANSFER",
    text: "Bank Transfer",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_CARD_SUBTITLE_1",
    text: "Credit Card as Guarantee - Secure Site",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_CARD_SUBTITLE_2",
    text: "Your credit card details are requested as a guarantee for the booking and any extras.",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_CARD_SUBTITLE_3",
    text: "reserves the right to temporarily hold an amount equal to the first night prior to your arrival in the form of a pre-authorization on the provided credit card. We recommend using a credit card instead of a debit or prepaid card to reduce banking handling times for the release of the potential pre-authorization.",
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_1",
    text: "I have read and approve the data protection conditions indicated",
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_2",
    text: "in the privacy policy",
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_3",
    text: "By confirming this booking I also accept the",
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_4",
    text: "general terms",
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_5",
    text: " and the booking conditions listed above for each unit.",
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_6",
    text: "I wish to receive information about special offers and news.",
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_7",
    text: "I have read and approve the data protection conditions indicated.",
  },
  {
    label: "LABEL_PAYMENT_FORM_BUTTON_1",
    text: "Confirm Booking",
  },
  {
    label: "LABEL_PAYMENT_FORM_BUTTON_2",
    text: "Pay Online",
  },
  {
    label: "LABEL_PAYMENT_FORM_BUTTON_SUBTITLE_1",
    text: "There is one more step before completing the procedure",
  },
  {
    label: "LABEL_PAYMENT_FORM_ONE_MOMENT",
    text: "It will only take a moment",
  },
  {
    label: "BT_TITLE",
    text: "Payment Method: Bank Transfer",
  },
  {
    label: "BT_SUBTITLE",
    text: "Instructions:",
  },
  {
    label: "BT_TEXT1",
    text: "Please make the payment within the next",
  },
  {
    label: "BT_TEXT1_5",
    text: " days. As soon as we receive the transfer, we will send you a confirmation email with the details of your purchase.",
  },
  {
    label: "BT_TEXT2",
    text: "Once the transfer is made, remember to save a copy of the receipt and upload it below.",
  },
  {
    label: "BT_TEXT3",
    text: "Important",
  },
  {
    label: "BT_TEXT4",
    text: "We do not check credits daily, so do not forget to upload the transfer receipt below, otherwise the booking will not be valid.",
  },
  {
    label: "BT_TEXT5",
    text: "* Please enter the details correctly, especially the purpose, to facilitate the processing of your order",
  },
  {
    label: "BT_FORM_1",
    text: "IBAN:",
  },
  {
    label: "BT_FORM_2",
    text: "Purpose:",
  },
  {
    label: "BT_FORM_3",
    text: "Beneficiary:",
  },
  {
    label: "BT_FORM_4",
    text: "Amount:",
  },
  {
    label: "BT_FORM_5",
    text: "Upload and send transfer",
  },
  {
    label: "BT_PAY_1",
    text: "Drop the files here!",
  },
  {
    label: "BT_PAY_2",
    text: "Drag and drop your receipt here!",
  },
  {
    label: "BT_PAY_3",
    text: "Or click here to select files",
  },
  {
    label: "BT_PAY_4",
    text: "Your file is ready for upload.",
  },
  {
    label: "BT_PAY_5",
    text: `Press "Upload and send transfer" to confirm the booking.`,
  },
  {
    label: "CARD_FORM_1",
    text: "Card Number",
  },
  {
    label: "CARD_FORM_2",
    text: "Cardholder's Name",
  },
  {
    label: "CARD_FORM_3",
    text: "Expiry Date",
  },
  {
    label: "CARD_FORM_4",
    text: "Month",
  },
  {
    label: "CARD_FORM_5",
    text: "Year",
  },
  {
    label: "CARD_FORM_6",
    text: "Confirm Booking",
  },
  {
    label: "CARD_FORM_7",
    text: "FULL NAME",
  },
  {
    label: "CARD_FORM_8",
    text: "Expiration",
  },
  {
    label: "SUCCESS_THANK_YOU",
    text: "Thank you",
  },
  {
    label: "SUCCESS_THANK_YOU_1",
    text: "your booking is confirmed",
  },
  {
    label: "SUCCESS_THANK_YOU_2",
    text: "An email has been sent to",
  },
  {
    label: "SUCCESS_THANK_YOU_3",
    text: "with all the details.",
  },
  {
    label: "SUCCESS_THANK_YOU_4",
    text: "is expecting you on",
  },
  {
    label: "SUCCESS_THANK_YOU_5",
    text: "The payment",
  },
  {
    label: "SUCCESS_THANK_YOU_6",
    text: "will be managed by",
  },
  {
    label: "SUCCESS_THANK_YOU_7",
    text: "Modify your booking or ask the property a question in just a few clicks.",
  },
  {
    label: "SUCCESS_THANK_YOU_8",
    text: "Booking Details",
  },
  {
    label: "SUCCESS_THANK_YOU_9",
    text: "Your booking",
  },
  {
    label: "SUCCESS_THANK_YOU_10",
    text: "Booking Code",
  },
  {
    label: "SUCCESS_THANK_YOU_11",
    text: "Pin for modifications",
  },
  {
    label: "SUCCESS_THANK_YOU_12",
    text: "Booked under the name of",
  },
  {
    label: "SUCCESS_THANK_YOU_13",
    text: "Your stay's extras",
  },
  {
    label: "SUCCESS_THANK_YOU_14",
    text: "Quantity:",
  },
  {
    label: "SUCCESS_THANK_YOU_15",
    text: "Taxes",
  },
  {
    label: "SUCCESS_THANK_YOU_16",
    text: "To be paid at the property",
  },
  {
    label: "SUCCESS_THANK_YOU_17",
    text: "The package you selected",
  },
  {
    label: "SUCCESS_THANK_YOU_18",
    text: "You have entered a discount code",
  },
  {
    label: "PAYMENT_LOADING_TEXT_1",
    text: "We are contacting the property ...",
  },
  {
    label: "PAYMENT_LOADING_TEXT_2",
    text: "We are saving your booking ...",
  },
  {
    label: "PAYMENT_LOADING_TEXT_3",
    text: "We are confirming the status ...",
  },
  {
    label: "ERROR_TITLE",
    text: "Oops.. something went wrong",
  },
  {
    label: "ERROR_CODE_5_TEXT",
    text: "Unfortunately, one or more rooms in your selection have already been booked. You will be redirected to a new search.",
  },
  {
    label: "ERROR_CODE_GENERIC_TEXT",
    text: "The booking could not be created. We recommend contacting the property.",
  },
  {
    label: "LABEL_ERROR_ALERT_1",
    text: "Oops.. The room is already occupied",
  },
  {
    label: "LABEL_BODY_ERROR_ALERT_1",
    text: "It seems you are already booking an identical room, but the property no longer has availability for this type. Please perform a new search.",
  },
  {
    label: "LABEL_TITLE_ROOM_ADDED_CART",
    text: "The room is saved!",
  },
  {
    label: "LABEL_PRIVACY_POLICY",
    text: "Privacy terms and conditions (PRIVACY POLICY)",
  },
  {
    label: "LABEL_BOOKING_TERMS",
    text: "Booking terms and conditions (BOOKING TERMS)",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART1",
    text: "Pursuant to Article 13 of Regulation (EU) 2016/679 on personal data protection, we inform you that your personal data, freely provided by you, will be processed by F451 S.R.L, hereafter Data Controller, primarily through IT tools to follow up on your request to subscribe to our automated newsletter that will allow you to be updated on the services we offer and our promotional activities, as well as to know your satisfaction regarding the services you enjoyed, fulfill market research, and send you advertising material.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART2",
    text: "You are reminded that you will always have the option to revoke consent to this subscription.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART3",
    text: "Providing your personal data is free; however, failing to provide it will not allow us to comply with your request and, consequently, to subscribe you to our newsletter.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART4",
    text: "Should you decide to provide us with your data, we inform you that it will be processed exclusively by personnel authorized by the Data Controller and will only be stored for as long as necessary to achieve the purpose mentioned above.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART5",
    text: "Under no circumstances will your data be disseminated but may be communicated to third parties the Controller relies on solely to ensure proper pursuit of the purpose mentioned above.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART6",
    text: "You are reminded that you are guaranteed the following rights: right of access, right of rectification, right to deletion, right to restriction, right to portability, right to withdraw consent, right to complaint, right to opposition, and the right not to be subject to a decision based solely on automated processing, including profiling, except in cases provided for by the Regulation. To exercise such rights, you may contact the Data Controller by writing to F451 S.R.L. - Via Giuseppe Parini 13 or sending an email to sviluppo@idaitalia.com. Without prejudice to any other administrative or judicial appeal, you have the right to lodge a complaint with a Supervisory Authority if you believe that the processing concerning you violates the Regulation.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_TITLE",
    text: "Newsletter and Marketing",
  },
  {
    label: "ROOM_TREATMENT",
    text: "Treatment:",
  },
  {
    label: "LABEL_SHOW_MORE",
    text: "See more",
  },
  {
    label: "LABEL_SAVE_ROOM",
    text: "Add room",
  },
  {
    label: "TITLE_CANCELLATION_POLICY_NOREFUNDABLE",
    text: "Cancellation Policy",
  },
  {
    label: "LABEL_CANCELLATION_POLICY_NOREFUNDABLE",
    text: "Full charge at the time of booking without modification or cancellation possibility.",
  },
  {
    label: "LABEL_NOREFUNDABLE",
    text: "Non-refundable",
  },
  {
    label: "BUTTON_NOREFUNDABLE",
    text: "Non-refundable",
  },
  {
    label: "PACKET_STARTING",
    text: "Starting from",
  },
  {
    label: "PACKET_FOR",
    text: "for",
  },
  {
    label: "PACKET_NIGHTS",
    text: "nights.",
  },
  {
    label: "PACKET_SHOW",
    text: "See all packages",
  },
  {
    label: "PACKET_CHOOSE",
    text: "Choose package",
  },
  {
    label: "PACKET_BACK",
    text: "Go back",
  },
  {
    label: "PACKET_CHOOSE_DATE",
    text: "Choose a date:",
  },
  {
    label: "PACKET_VALID_PERIOD",
    text: "You have selected a valid period",
  },
  {
    label: "PACKET_WRONG_PERIOD",
    text: "You have selected an invalid period",
  },
  {
    label: "PACKET_SELECT_ROOM",
    text: "Select a room",
  },
];
