import { Card, CircularProgress, Grid, TextField } from "@mui/material";
import InputText from "../input/input";
import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import DynamicHeroIcon from "../heroicons/hicon";
import MainScreen from "./card/card";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Privacy from "../privacy/privacy";
import countryList from "react-select-country-list";
import { useMemo } from "react";
import NewsletterPolicy from "../newsletter_policy/newsletter_policy";
import { Checkmark } from "react-checkmark";
import TextTransition, { presets } from "react-text-transition";
import structureService from "../../services/structure.service";
import { useNavigate } from "react-router-dom";
import Bonifico from "./bonifico";
import { GetLabel } from "../../languages/i18n";
import { isMobile } from "react-device-detect";
import "react-phone-input-2/lib/material.css";
import { MuiTelInput } from "mui-tel-input";
import { IoClose } from "react-icons/io5";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: !isMobile ? 600 : "100%",
  bgcolor: "transparent",
  p: 4,
};

function GetTexts(language) {
  return [
    GetLabel(language, "PAYMENT_LOADING_TEXT_1"),
    GetLabel(language, "PAYMENT_LOADING_TEXT_2"),
    GetLabel(language, "PAYMENT_LOADING_TEXT_3"),
  ];
}
export default function PaymentCards(props) {
  const [texts, setTexts] = React.useState(GetTexts(props.language));

  const [info, setInfo] = React.useState({
    name: {
      value: "",
      is_invalid: false,
      message: GetLabel(props.language, "LABEL_PAYMENT_FORM_ERROR_NAME"),
      error_message: "",
    },
    surname: {
      value: "",
      is_invalid: false,
      message: GetLabel(props.language, "LABEL_PAYMENT_FORM_ERROR_SURNAME"),
      error_message: "",
    },
    email: {
      value: "",
      is_invalid: false,
      message: GetLabel(props.language, "LABEL_PAYMENT_FORM_ERROR_EMAIL"),
      error_message: "",
    },
    confirmEmail: {
      value: "",
      is_invalid: false,
      message: GetLabel(props.language, "LABEL_PAYMENT_FORM_ERROR_EMAIL"),
      error_message: "",
    },
    phone: {
      value: "",
      is_invalid: false,
      message: GetLabel(props.language, "LABEL_PAYMENT_FORM_ERROR_PHONE"),
      error_message: "",
    },
    confirmPhone: {
      value: "",
      is_invalid: false,
      message: GetLabel(props.language, "LABEL_PAYMENT_FORM_ERROR_PHONE"),
      error_message: "",
    },
    country: {
      value: "",
      is_invalid: false,
      message: "",
      error_message: "",
    },
    city: {
      value: "",
      is_invalid: false,
      message: "",
      error_message: "",
    },
    zip_code: {
      value: "",
      is_invalid: false,
      message: "",
      error_message: "",
    },
    address: {
      value: "",
      is_invalid: false,
      message: "",
      error_message: "",
    },
    note: {
      value: "",
    },
  });

  const [paymentType, setPaymentType] = React.useState(1);
  const [protectionData, setProtectionData] = React.useState(false);
  const [commercialData, setCommercialData] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [showPrivacy, setShowPrivacy] = React.useState(false);
  const [showNewsPolicy, setShowNewsPolicy] = React.useState(false);
  const options = useMemo(() => countryList().getData(), []);
  const [index, setIndex] = React.useState(0);
  const [isCreatingBooking, setIsCreatingBooking] = React.useState(false);
  const [openBonifico, setOpenBonifico] = React.useState(false);
  const [transactionId, setTransactionId] = React.useState(null);
  const [createBookingPending, setCreateBookingPending] = React.useState(false);
  const [loadingBonificoDetails, setLoadingBonificoDetails] =
    React.useState(false);
  const [bonificoDetails, setBonificoDetails] = React.useState({
    iban: "",
    causale: "",
    beneficiario: "",
    bonifico_day: "",
  });
  const [isPaymentModalOpen, setIsPaymentModalOpen] = React.useState(false);
  const [paymentUrl, setPaymentUrl] = React.useState("");

  let navigate = useNavigate();

  React.useEffect(() => {
    validateForm();
  }, [
    info.name.value,
    info.surname.value,
    info.confirmEmail.value,
    info.email.value,
    info.phone.value,
    info.confirmPhone.value,
    info.country.value,
    protectionData,
    commercialData,
  ]);

  React.useEffect(() => {
    validateForm();
  }, []);

  React.useEffect(() => {
    options.push({ value: "WAK", label: "Wakanda" });
  }, [options]);

  React.useEffect(() => {
    if (openBonifico) {
      getBonificoDetails();
    }
  }, [openBonifico]);

  React.useEffect(() => {
    setTexts(GetTexts(props.language));
    setInfo({
      ...info,
      name: {
        ...info.name,
        message: GetLabel(props.language, "LABEL_PAYMENT_FORM_ERROR_NAME"),
        error_message: info.name.is_invalid
          ? GetLabel(props.language, "LABEL_PAYMENT_FORM_ERROR_NAME")
          : "",
      },
      surname: {
        ...info.surname,
        message: GetLabel(props.language, "LABEL_PAYMENT_FORM_ERROR_SURNAME"),
        error_message: info.surname.is_invalid
          ? GetLabel(props.language, "LABEL_PAYMENT_FORM_ERROR_SURNAME")
          : "",
      },
      confirmEmail: {
        ...info.confirmEmail,
        message: GetLabel(props.language, "LABEL_PAYMENT_FORM_ERROR_EMAIL"),
        error_message: info.confirmEmail.is_invalid
          ? GetLabel(props.language, "LABEL_PAYMENT_FORM_ERROR_EMAIL")
          : "",
      },
      email: {
        ...info.email,
        message: GetLabel(props.language, "LABEL_PAYMENT_FORM_ERROR_EMAIL"),
        error_message: info.email.is_invalid
          ? GetLabel(props.language, "LABEL_PAYMENT_FORM_ERROR_EMAIL")
          : "",
      },
      phone: {
        ...info.phone,
        message: GetLabel(props.language, "LABEL_PAYMENT_FORM_ERROR_PHONE"),
        error_message: info.phone.is_invalid
          ? GetLabel(props.language, "LABEL_PAYMENT_FORM_ERROR_PHONE")
          : "",
      },
      confirmPhone: {
        ...info.confirmPhone,
        message: GetLabel(props.language, "LABEL_PAYMENT_FORM_ERROR_PHONE"),
        error_message: info.confirmPhone.is_invalid
          ? GetLabel(props.language, "LABEL_PAYMENT_FORM_ERROR_PHONE")
          : "",
      },
    });
  }, [props.language]);

  const getBonificoDetails = async () => {
    setLoadingBonificoDetails(true);
    structureService.getStructureBonificoDetails(props.uuid).then(
      (result) => {
        result = result.data;
        setBonificoDetails(result);
        setLoadingBonificoDetails(false);
      },
      (error) => {}
    );
  };
  const CreateBooking = (obj, pay_with_card) => {
    console.log(pay_with_card);
    setIsCreatingBooking(true);

    // crea il nuovo oggetto booking da spedire
    const save_booking_model = {
      token: props.token,
      responsabile: {
        name: info.name.value,
        surname: info.surname.value,
        email: info.email.value,
        phone: info.phone.value,
        confirmPhone: info.confirmPhone.value,
        country: info.country.value,
        city: info.city.value,
        zip_code: info.zip_code.value,
        address: info.address.value,
        note: info.note.value,
      },
      card: {
        cardNumber: obj ? obj.cardNumber : "",
        cardHolder: obj ? obj.cardHolder : "",
        cardMonth: obj ? obj.cardMonth : "",
        cardYear: obj ? obj.cardYear : "",
        cardCVV: obj ? obj.cardCvv : "",
      },
      extras: props.extras,
      language: props.language,
      transactionId: transactionId,
    };

    if (pay_with_card) {
      structureService.saveBookingHobex(save_booking_model).then(
        (result) => {
          result = result.data;
          console.log(result);
          if (result.success === true) window.location.replace(result.model);
        },
        (error) => {
          setIsCreatingBooking(false);
          setOpen(false);

          var error_code = error.response.data.ExceptionMessage;
          if (error_code === "CODE5") {
            navigate("/payment/error", {
              state: {
                title: GetLabel(props.language, "ERROR_TITLE"),
                error: GetLabel(props.language, "ERROR_CODE_5_TEXT"),
              },
            });
          } else {
            navigate("/payment/error", {
              state: {
                title: GetLabel(props.language, "ERROR_TITLE"),
                error:
                  GetLabel(props.language, "ERROR_CODE_GENERIC_TEXT") +
                  error_code,
              },
            });
          }
        }
      );
    } else {
      structureService.saveBooking(save_booking_model).then(
        (result) => {
          result = result.data;
          if (result.success === true)
            navigate("/success/" + props.uuid + "/" + props.token);
        },
        (error) => {
          setIsCreatingBooking(false);
          setOpen(false);

          var error_code = error.response.data.ExceptionMessage;
          if (error_code === "CODE5") {
            navigate("/payment/error", {
              state: {
                title: GetLabel(props.language, "ERROR_TITLE"),
                error: GetLabel(props.language, "ERROR_CODE_5_TEXT"),
              },
            });
          } else {
            navigate("/payment/error", {
              state: {
                title: GetLabel(props.language, "ERROR_TITLE"),
                error:
                  GetLabel(props.language, "ERROR_CODE_GENERIC_TEXT") +
                  error_code,
              },
            });
          }
        }
      );

      const intervalId = setTimeout(() => setIndex((index) => index + 1), 3000);
    }
  };
  React.useEffect(() => {
    if (createBookingPending && transactionId !== null) {
      // Ora che transactionId è aggiornato, chiamiamo CreateBooking
      CreateBooking();
      // Resettiamo il flag
      setCreateBookingPending(false);
    }
  }, [createBookingPending, transactionId]);
  const PaymentModal = ({ isOpen, onClose, paymentUrl }) => {
    if (!isOpen) return null;

    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 9999,
        }}
      >
        <div
          style={{
            background: "white",
            paddingTop: "20px",
            borderRadius: "10px",
            width: "80%",
            maxWidth: "600px",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <iframe
            src={paymentUrl}
            style={{ width: "100%", height: "500px", border: "none" }}
          />
        </div>
      </div>
    );
  };
  const showCardPayment = () => {
    if (paymentType === 1 && props.structureInfo.is_pay_by_link) {
      const payment = {
        id_booking: null,
        amount: props.total,
        address: info.address.value,
        municipality: info.city.value,
        country: info.country.value,
        email: info.email.value,
        name: info.name.value,
        surname: info.surname.value,
        telephone: info.phone.value,
      };

      structureService
        .createPaymentLink(`${props.uuid}/${props.language}`, payment)
        .then(
          (result) => {
            result = result.data;
            const paymentPageUrl = result.paymentLink;
            const transactionId_result = result.transactionId;

            setTransactionId(transactionId_result);
            setPaymentUrl(paymentPageUrl);
            setIsPaymentModalOpen(true);

            const checkPaymentStatus = () => {
              structureService
                .getStatusLink(
                  `${props.uuid}/${props.language}/${transactionId_result}`
                )
                .then((statusResult) => {
                  if (statusResult) {
                    clearInterval(paymentStatusInterval);
                    setIsPaymentModalOpen(false);
                    handleOpen();
                    setIsCreatingBooking(true);
                    // CreateBooking();
                    setCreateBookingPending(true);
                  }
                })
                .catch((error) => {
                  console.error(
                    "Errore nel controllo dello stato del pagamento:",
                    error
                  );
                });
            };

            const paymentStatusInterval = setInterval(
              checkPaymentStatus,
              10000
            );
          },
          (error) => {}
        );
    } else if (paymentType === 1) setOpen(true);
    else setOpenBonifico(true);
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    if (!isCreatingBooking) setOpen(false);
  };
  const isEmailValid = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };
  const isPhoneNumberValid = (phoneNumber) => {
    // Regex per controllare il formato del numero di telefono (esempio: solo cifre e lunghezza minima di 10 caratteri)
    // const phoneRegex = /^\+\d{1,3}(\s\d{1,4}){1,4}$/;
    const phoneRegex = /^\+\d{1,3}[\s-]?(\d{1,4}[\s-]?){1,5}\d{1,6}$/;
    return phoneRegex.test(phoneNumber);
  };
  const isOnlyAlfabetic = (field) => {
    // Regex per verificare se il cognome contiene solo lettere (a-z, A-Z) e spazi
    return /^[a-zA-Z\s]+$/.test(field);
  };
  const validateForm = () => {
    var old_info = info;
    var new_is_disabled = false;

    if (info.name.value === "" || !isOnlyAlfabetic(info.name.value)) {
      const em = info.name.message;
      old_info.name.error_message = em;
      old_info.name.is_invalid = true;
      new_is_disabled = true;
    } else {
      old_info.name.error_message = "";
      old_info.name.is_invalid = false;
    }

    if (info.surname.value === "" || !isOnlyAlfabetic(info.surname.value)) {
      const em = info.surname.message;
      old_info.surname.error_message = em;
      old_info.surname.is_invalid = true;
      new_is_disabled = true;
    } else {
      old_info.surname.error_message = "";
      old_info.surname.is_invalid = false;
    }

    if (info.email.value === "" || !isEmailValid(info.email.value)) {
      const em = info.email.message;
      old_info.email.error_message = em;
      old_info.email.is_invalid = true;
      new_is_disabled = true;
    } else {
      old_info.email.error_message = "";
      old_info.email.is_invalid = false;
    }

    // Controllo dell'indirizzo email di conferma
    if (
      info.confirmEmail.value === "" ||
      !isEmailValid(info.confirmEmail.value)
    ) {
      const em = info.confirmEmail.message;
      old_info.confirmEmail.error_message = em;
      old_info.confirmEmail.is_invalid = true;
      new_is_disabled = true;
    } else if (info.confirmEmail.value !== info.email.value) {
      // Controlla solo se l'indirizzo email di conferma non corrisponde all'indirizzo email principale
      const em = info.confirmEmail.message;
      old_info.confirmEmail.error_message = em;
      old_info.confirmEmail.is_invalid = true;
      new_is_disabled = true;
    } else {
      old_info.confirmEmail.error_message = "";
      old_info.confirmEmail.is_invalid = false;
    }

    if (info.phone.value === "" || !isPhoneNumberValid(info.phone.value)) {
      const em = "Numero di telefono non valido";
      old_info.phone.error_message = em;
      old_info.phone.is_invalid = true;
      new_is_disabled = true;
    } else {
      old_info.phone.error_message = "";
      old_info.phone.is_invalid = false;
    }
    if (
      info.confirmPhone.value === "" ||
      !isPhoneNumberValid(info.confirmPhone.value)
    ) {
      const em = "Numero di telefono di conferma non valido";
      old_info.confirmPhone.error_message = em;
      old_info.confirmPhone.is_invalid = true;
      new_is_disabled = true;
    } else if (info.confirmPhone.value !== info.phone.value) {
      const em =
        "Il numero di telefono di conferma non corrisponde al numero di telefono principale";
      old_info.confirmPhone.error_message = em;
      old_info.confirmPhone.is_invalid = true;
      new_is_disabled = true;
    } else {
      old_info.confirmPhone.error_message = "";
      old_info.confirmPhone.is_invalid = false;
    }

    // if (info.phone.value === "") {
    //   const em = info.phone.message;
    //   old_info.phone.error_message = em;
    //   old_info.phone.is_invalid = true;
    //   new_is_disabled = true;
    // } else {
    //   old_info.phone.error_message = "";
    //   old_info.phone.is_invalid = false;
    // }

    // if (info.confirmPhone.value === "") {
    //   const em = info.confirmPhone.message;
    //   old_info.confirmPhone.error_message = em;
    //   old_info.confirmPhone.is_invalid = true;
    //   new_is_disabled = true;
    // } else {
    //   old_info.confirmPhone.error_message = "";
    //   old_info.confirmPhone.is_invalid = false;
    // }

    // if (info.confirmPhone.value !== info.phone.value) {
    //   const em = info.confirmPhone.message;
    //   old_info.confirmPhone.error_message = em;
    //   old_info.confirmPhone.is_invalid = true;
    //   new_is_disabled = true;
    // }

    // if (info.confirmEmail.value !== info.email.value) {
    //   const em = info.confirmEmail.message;
    //   old_info.confirmEmail.error_message = em;
    //   old_info.confirmEmail.is_invalid = true;
    //   new_is_disabled = true;
    // }

    if (info.country.value === "") {
      const em = info.country.message;
      old_info.country.error_message = em;
      old_info.country.is_invalid = true;
      new_is_disabled = true;
    } else {
      old_info.country.error_message = "";
      old_info.country.is_invalid = false;
    }

    setInfo({
      ...info,
      name: {
        ...info.name,
        error_message: old_info.name.error_message,
        is_invalid: old_info.name.is_invalid,
      },
      surname: {
        ...info.surname,
        error_message: old_info.surname.error_message,
        is_invalid: old_info.surname.is_invalid,
      },
      email: {
        ...info.email,
        error_message: old_info.email.error_message,
        is_invalid: old_info.email.is_invalid,
      },
      confirmEmail: {
        ...info.confirmEmail,
        error_message: old_info.confirmEmail.error_message,
        is_invalid: old_info.confirmEmail.is_invalid,
      },
      phone: {
        ...info.phone,
        error_message: old_info.phone.error_message,
        is_invalid: old_info.phone.is_invalid,
      },
      confirmPhone: {
        ...info.confirmPhone,
        error_message: old_info.confirmPhone.error_message,
        is_invalid: old_info.confirmPhone.is_invalid,
      },
    });

    if (protectionData === false) new_is_disabled = true;

    setIsDisabled(new_is_disabled);
  };

  return (
    <Card sx={{ borderRadius: "10px", marginTop: "20px" }}>
      <PaymentModal
        isOpen={isPaymentModalOpen}
        onClose={() => setIsPaymentModalOpen(false)}
        paymentUrl={paymentUrl}
      />
      <Bonifico
        info={info}
        token={props.token}
        extras={props.extras}
        open={openBonifico}
        setOpen={setOpenBonifico}
        uuid={props.uuid}
        loadingBonificoDetails={loadingBonificoDetails}
        bonificoDetails={bonificoDetails}
        total={props.total}
        language={props.language}
      ></Bonifico>

      <Privacy
        open={showPrivacy}
        setOpen={setShowPrivacy}
        uuid={props.uuid}
        language={props.language}
      ></Privacy>
      <NewsletterPolicy
        open={showNewsPolicy}
        setOpen={setShowNewsPolicy}
        language={props.language}
      ></NewsletterPolicy>

      <h4
        style={{
          padding: "20px",
          fontSize: "20px",
          fontWeight: "400",
          margin: "0",
          marginTop: "10px",
          paddingBottom: "0",
        }}
      >
        {GetLabel(props.language, "LABEL_PAYMENT_INSERT_YOUR_DATA")}
      </h4>
      <p
        style={{
          padding: "20px",
          margin: "0",
          paddingTop: "10px",
          fontSize: "14px",
        }}
      >
        <font style={{ color: "red" }}>*</font>
        <span style={{ marginLeft: "10px" }}>
          {GetLabel(props.language, "LABEL_PAYMENT_NO_MANDATORY")}
        </span>
      </p>

      <Grid container spacing={2} sx={{ padding: "20px", paddingTop: "0" }}>
        <Grid item sm={3} xs={12}>
          <InputText
            label={GetLabel(props.language, "LABEL_PAYMENT_FORM_NAME")}
            value={info.name.value}
            onChange={(e) =>
              setInfo({
                ...info,
                name: { ...info.name, value: e.target.value },
              })
            }
            is_invalid={info.name.is_invalid}
            error={info.name.error_message}
          ></InputText>
        </Grid>
        <Grid item sm={3} xs={12}>
          <InputText
            label={GetLabel(props.language, "LABEL_PAYMENT_FORM_SURNAME")}
            value={info.surname.value}
            onChange={(e) =>
              setInfo({
                ...info,
                surname: { ...info.surname, value: e.target.value },
              })
            }
            is_invalid={info.surname.is_invalid}
            error={info.surname.error_message}
          ></InputText>
        </Grid>

        <Grid item sm={3} xs={12}>
          <InputText
            label={GetLabel(props.language, "LABEL_PAYMENT_FORM_EMAIL")}
            value={info.email.value}
            onChange={(e) =>
              setInfo({
                ...info,
                email: { ...info.email, value: e.target.value },
              })
            }
            is_invalid={info.email.is_invalid}
            error={info.email.error_message}
          ></InputText>
        </Grid>
        <Grid item sm={3} xs={12}>
          <InputText
            label={GetLabel(props.language, "LABEL_PAYMENT_FORM_CONFIRM_EMAIL")}
            value={info.confirmEmail.value}
            onChange={(e) =>
              setInfo({
                ...info,
                confirmEmail: { ...info.confirmEmail, value: e.target.value },
              })
            }
            is_invalid={info.confirmEmail.is_invalid}
            error={info.confirmEmail.error_message}
          ></InputText>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
            <MuiTelInput
              label={GetLabel(props.language, "LABEL_PAYMENT_FORM_PHONE")}
              value={info.phone.value}
              onChange={(phone) =>
                setInfo({
                  ...info,
                  phone: { ...info.phone, value: phone },
                })
              }
              is_invalid={info.phone.is_invalid}
              error={info.phone.error_message}
              defaultCountry={"IT"}
            />
          </Box>
        </Grid>

        <Grid item sm={4} xs={12}>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
            <MuiTelInput
              label={GetLabel(
                props.language,
                "LABEL_PAYMENT_CONFIRM_FORM_PHONE"
              )}
              value={info.confirmPhone.value}
              onChange={(phone) =>
                setInfo({
                  ...info,
                  confirmPhone: { ...info.confirmPhone, value: phone },
                })
              }
              is_invalid={info.confirmPhone.is_invalid}
              error={info.confirmPhone.error_message}
              defaultCountry={"IT"}
            />
          </Box>
        </Grid>
        <Grid item sm={4} xs={12}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel id="demo-simple-select-label">
              {GetLabel(props.language, "LABEL_PAYMENT_FORM_COUNTRY")}
            </InputLabel>
            <Select
              error={info.country.is_invalid}
              is_invalid={info.country.is_invalid}
              value={info.country.value}
              label="Stato"
              onChange={(e) =>
                setInfo({
                  ...info,
                  country: { ...info.country, value: e.target.value },
                })
              }
            >
              {options.map((opt) => {
                return <MenuItem value={opt.value}>{opt.label}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Grid>

        {info.country.value === "IT" && (
          <>
            <Grid item sm={4} xs={12}>
              <InputText
                label={GetLabel(props.language, "LABEL_PAYMENT_FORM_CITY")}
                value={info.city.value}
                onChange={(e) =>
                  setInfo({
                    ...info,
                    city: { ...info.city, value: e.target.value },
                  })
                }
                is_invalid={info.city.is_invalid}
                error={info.city.error_message}
              ></InputText>
            </Grid>
            <Grid item sm={4} xs={12}>
              <InputText
                label={GetLabel(props.language, "LABEL_PAYMENT_FORM_ZIP_CODE")}
                value={info.zip_code.value}
                onChange={(e) =>
                  setInfo({
                    ...info,
                    zip_code: { ...info.zip_code, value: e.target.value },
                  })
                }
                is_invalid={info.zip_code.is_invalid}
                error={info.zip_code.error_message}
              ></InputText>
            </Grid>
            <Grid item sm={4} xs={12}>
              <InputText
                label={GetLabel(props.language, "LABEL_PAYMENT_FORM_ADDRESS")}
                value={info.address.value}
                onChange={(e) =>
                  setInfo({
                    ...info,
                    address: { ...info.address, value: e.target.value },
                  })
                }
                is_invalid={info.address.is_invalid}
                error={info.address.error_message}
              ></InputText>
            </Grid>
          </>
        )}
        <Grid item xs={12} sx={{ m: 1 }}>
          <TextField
            label={GetLabel(props.language, "LABEL_PAYMENT_FORM_NOTES")}
            multiline
            rows={6}
            defaultValue={info.note.value}
            onChange={(e) =>
              setInfo({
                ...info,
                note: { ...info.note, value: e.target.value },
              })
            }
            sx={{ width: "100%" }}
          />
        </Grid>
      </Grid>

      <h4
        style={{
          padding: "20px",
          fontSize: "20px",
          fontWeight: "400",
          margin: "0",
          marginTop: "10px",
          paddingBottom: "0",
        }}
      >
        {GetLabel(props.language, "LABEL_PAYMENT_FORM_PAYMENT_METHODS")}
      </h4>
      <p
        style={{
          padding: "20px",
          margin: "0",
          paddingTop: "10px",
          fontSize: "14px",
        }}
      >
        <span>
          {GetLabel(props.language, "LABEL_PAYMENT_FORM_PAYMENT_METHODS_1")}
        </span>
      </p>

      <Grid container spacing={2} sx={{ padding: "20px", paddingTop: "0" }}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel>
              {GetLabel(props.language, "LABEL_PAYMENT_FORM_PAYMENT_METHODS_2")}
            </InputLabel>
            <Select
              value={paymentType}
              label={GetLabel(
                props.language,
                "LABEL_PAYMENT_FORM_PAYMENT_METHODS_2"
              )}
              onChange={(e) => setPaymentType(e.target.value)}
            >
              <MenuItem value={1}>
                {GetLabel(props.language, "LABEL_PAYMENT_FORM_PAYMENT_CARD")}
              </MenuItem>
              {props.structureInfo.structure_iban &&
                props.structureInfo.structure_iban !== "" && (
                  <MenuItem value={2}>
                    {GetLabel(
                      props.language,
                      "LABEL_PAYMENT_FORM_PAYMENT_TRANSFER"
                    )}
                  </MenuItem>
                )}
            </Select>
          </FormControl>
        </Grid>

        {paymentType === 1 ? (
          <div style={{ padding: "20px" }}>
            <h4>
              {GetLabel(
                props.language,
                "LABEL_PAYMENT_FORM_PAYMENT_CARD_SUBTITLE_1"
              )}
            </h4>
            {GetLabel(
              props.language,
              "LABEL_PAYMENT_FORM_PAYMENT_CARD_SUBTITLE_2"
            )}
            <br></br>
            <br></br>
            <span>{props.structureInfo.structure_name} </span>
            {GetLabel(
              props.language,
              "LABEL_PAYMENT_FORM_PAYMENT_CARD_SUBTITLE_3"
            )}
          </div>
        ) : (
          <></>
        )}

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                value={protectionData}
                onChange={(e) => setProtectionData(e.target.checked)}
              />
            }
            label={
              <>
                {GetLabel(props.language, "LABEL_PAYMENT_FORM_ACCEPT_1")}
                <span
                  className="fake-link"
                  onClick={() => setShowPrivacy(true)}
                >
                  {GetLabel(props.language, "LABEL_PAYMENT_FORM_ACCEPT_2")}
                </span>
                .<br></br>
                {GetLabel(props.language, "LABEL_PAYMENT_FORM_ACCEPT_3")}
                <span
                  className="fake-link"
                  onClick={() => setShowPrivacy(true)}
                >
                  {GetLabel(props.language, "LABEL_PAYMENT_FORM_ACCEPT_4")}
                </span>
                &nbsp;
                {GetLabel(props.language, "LABEL_PAYMENT_FORM_ACCEPT_5")}
              </>
            }
            sx={{ marginBottom: "20px" }}
          />
          <FormControlLabel
            control={
              <Checkbox
                value={commercialData}
                onChange={(e) => setCommercialData(e.target.checked)}
              />
            }
            label={
              <>
                {GetLabel(props.language, "LABEL_PAYMENT_FORM_ACCEPT_6")}
                <br></br>
                {GetLabel(props.language, "LABEL_PAYMENT_FORM_ACCEPT_7")}&nbsp;
                <span
                  className="fake-link"
                  onClick={() => setShowNewsPolicy(true)}
                >
                  {GetLabel(props.language, "LABEL_PAYMENT_FORM_ACCEPT_2")}
                </span>
                .
              </>
            }
            sx={{ marginBottom: "20px" }}
          />
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          {/* <OnlinePayment
            language={props.language}
            uuid={props.uuid}
            disabled={isDisabled}
            onSubmit={CreateBooking}
          /> */}
          <LoadingButton
            fullWidth
            variant="contained"
            sx={{
              fontSize: "17px !important",
              fontWeight: "500 !important",
              marginLeft: "auto",
              marginTop: "15px",
            }}
            className="btn-primary"
            loading={isLoading}
            onClick={showCardPayment}
            disabled={isDisabled}
            loadingPosition="start"
            color="primary"
          >
            {GetLabel(props.language, "LABEL_PAYMENT_FORM_BUTTON_1")}
            <DynamicHeroIcon
              icon={"ArrowRightIcon"}
              className="input-icon"
              style={{ color: "#FFF", marginLeft: "5px" }}
            ></DynamicHeroIcon>
          </LoadingButton>
          <p
            style={{
              margin: "0",
              fontSize: "13px",
              color: "gray",
              textAlign: "center",
              marginTop: "10px",
            }}
          >
            {GetLabel(props.language, "LABEL_PAYMENT_FORM_BUTTON_SUBTITLE_1")}
          </p>
        </Grid>
      </Grid>

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          {isCreatingBooking === true ? (
            <Card
              style={{
                minHeight: "400px",
                borderRadius: "14px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress sx={{ marginBottom: "40px" }} />
                <TextTransition
                  springConfig={presets.wobbly}
                  style={{
                    color: "#445d72",
                    fontWeight: "400",
                    fontSize: "25px",
                  }}
                >
                  {texts[index % texts.length]}
                </TextTransition>
                <p
                  style={{
                    fontSize: "14px",
                    color: "#747099",
                    marginTop: "30px",
                  }}
                >
                  {GetLabel(props.language, "LABEL_PAYMENT_FORM_ONE_MOMENT")}
                </p>
              </div>
            </Card>
          ) : (
            <MainScreen
              submit={CreateBooking}
              country={info.country.value}
              language={props.language}
            ></MainScreen>
          )}
        </Box>
      </Modal>
    </Card>
  );
}
