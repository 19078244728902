export const IT_labels = [
  {
    label: "LABEL_GESTISCI_PRENOTAZIONE",
    text: "Gestisci Prenotazione",
  },
  {
    label: "LABEL_ROOM_NOT",
    text: "Non ",
  },
  {
    label: "LABEL_ROOM_REFUNDABLE",
    text: "Rimborsabile",
  },
  {
    label: "LABEL_SEARCH_CAMERE",
    text: "Camere",
  },
  {
    label: "LABEL_SEARCH_SELECT",
    text: "Seleziona",
  },
  {
    label: "LABEL_SEARCH_TYPE",
    text: "Tipologia",
  },
  {
    label: "LABEL_SEARCH_ALL",
    text: "Tutte",
  },
  {
    label: "LABEL_SEARCH_ADULTI",
    text: "Adulti",
  },
  {
    label: "LABEL_SEARCH_BAMBINI",
    text: "Bambini",
  },
  {
    label: "LABEL_SEARCH_ROOM_PLACEHOLDER",
    text: "Stanza",
  },
  {
    label: "LABEL_SEARCH_BUTTON",
    text: "Verifica Disponibilità",
  },
  {
    label: "LABEL_SEARCH_BUTTON_SEARCHING",
    text: "Sto cercando ...",
  },
  {
    label: "LABEL_SEARCH_COUPON_CODE",
    text: "Ho un codice sconto",
  },
  {
    label: "LABEL_SEARCH_PLACEHOLDER_COUPON",
    text: "Inserisci qui il tuo codice",
  },
  {
    label: "LABEL_CALENDAR_ARRIVO",
    text: "Arrivo",
  },
  {
    label: "LABEL_CALENDAR_PARTENZA",
    text: "Partenza",
  },
  {
    label: "LABEL_CALENDAR_NOTTI",
    text: "Notti",
  },
  {
    label: "LABEL_ROOM_DESCR_SHOW_MORE",
    text: "... Leggi di più",
  },
  {
    label: "LABEL_ROOM_DESCR_SHOW_LESS",
    text: "... Mostra meno",
  },
  {
    label: "LABEL_ROOM_GUESTS",
    text: "Ospiti",
  },
  {
    label: "LABEL_ROOM_ADDED_CART",
    text: "La tua camera è stata aggiunta al carrello",
  },
  {
    label: "LABEL_FROM_DAY",
    text: "dal giorno",
  },
  {
    label: "LABEL_FROM_DAY_SHORT",
    text: "Dal",
  },
  {
    label: "LABEL_TO_DAY",
    text: "al",
  },
  {
    label: "LABEL_ROOM_REMOVED",
    text: "non risulta più disponibile ed è stata rimossa",
  },
  {
    label: "LABEL_REMOVE_FROM_CART",
    text: "Rimuovi dal carrello",
  },
  {
    label: "LABEL_OPS_EMPTY",
    text: "Oops.. qui è vuoto",
  },
  {
    label: "LABEL_ADD_ROOM_PLACEHOLDER",
    text: "Aggiungi una stanza cercando quella più giusta per te...",
  },
  {
    label: "LABEL_TOTAL",
    text: "Totale",
  },
  {
    label: "LABEL_GOTO_PAYMENT",
    text: "Vai al pagamento",
  },
  {
    label: "LABEL_NO_ROOMS_FOUND",
    text: "Ci dispiace, ma non abbiamo stanze disponibili per le date cercate. ",
  },
  {
    label: "LABEL_NO_ROOMS_GG_MIN_1",
    text: "Ci dispiace, per le date selezionate la struttura richiede un soggiorno minimo di ",
  },
  {
    label: "LABEL_NO_ROOMS_GG_MIN_2",
    text: " notti.",
  },
  {
    label: "LABEL_NO_ROOMS_LAST_MINUTE",
    text: "Ci dispiace, non accettiamo prenotazioni last minute. Le prenotazioni per oggi sono chiuse.",
  },
  {
    label: "LABEL_CONTACT_US",
    text: "Vi preghiamo di rieffettuare la ricerca o di contattarci ai seguenti recapiti: ",
  },
  {
    label: "LABEL_SERVICES",
    text: "Servizi Disponibili",
  },
  {
    label: "LABEL_NO_SERVICE",
    text: "Nessun Servizio Disponibile",
  },
  {
    label: "LABEL_TAX_STAY",
    text: "Tassa di soggiorno",
  },
  {
    label: "LABEL_CURRENCY_LONG",
    text: "EUR",
  },
  {
    label: "LABEL_STAY_TAX_DESCR",
    text: "a notte a ospite da saldare in loco.",
  },
  {
    label: "LABEL_NO_TAX_TO_PAY",
    text: "Nessuna tassa di soggiorno da pagare.",
  },
  {
    label: "LABEL_DESCRIPTION",
    text: "Descrizione",
  },
  {
    label: "CIN_CODE",
    text: "Codice CIN: ",
  },
  {
    label: "LABEL_BOOKING_TERMS",
    text: "Condizioni di prenotazione e politiche cancellazione",
  },
  {
    label: "LABLE_OCCUPAZIONE_INFERIORE_TITLE",
    text: "Occupazione inferiore alla richiesta",
  },
  {
    label: "LABLE_OCCUPAZIONE_INFERIORE_DESCR",
    text: "L'occupazione delle camere selezionate è inferiore al numero di persone richiesto. Procedere comunque al pagamento?",
  },
  {
    label: "LABLE_OCCUPAZIONE_INFERIORE_YES",
    text: "Sì, procedi",
  },
  {
    label: "LABEL_FIXED_COST",
    text: "Costi Fissi",
  },
  {
    label: "LABEL_FIXED_COST_INCLUDED",
    text: " (inclusi costi fissi)",
  },
  {
    label: "LABEL_TOTAL_BOOKING_ROOM",
    text: "Totale Soggiorno",
  },
  {
    label: "TOOLTIP_ROOM_NOT_REFAUNDABLE",
    text: "Questa tariffa offre un prezzo vantaggioso, ma non prevede alcun rimborso in caso di cancellazione, modifica o mancata presentazione.",
  },
  {
    label: "LABEL_PRICE",
    text: "Prezzo",
  },
  {
    label: "LABEL_ADD_TO_CART",
    text: "Aggiungi al carello",
  },
  {
    label: "LABEL_BOOK_NOW",
    text: "Prenota Ora!",
  },
  {
    label: "LABEL_CHECK_ROOM_CART",
    text: "Vuoi tenere le camere nel carrello?",
  },
  {
    label: "LABEL_CHECK_ROOM_CART_YES",
    text: "Sì, salva le camere",
  },
  {
    label: "LABEL_CHECK_ROOM_CART_NO",
    text: "No",
  },

  {
    label: "LABEL_MINIMO_NOTTI",
    text: "Minimo di notti richieste",
  },
  {
    label: "LABEL_RESTRIZIONI",
    text: "Arrivo e partenza non permessi",
  },
  {
    label: "LABEL_PERMESSO_ARRIVO",
    text: "Ammesso solo l'arrivo",
  },
  {
    label: "LABEL_PERMESSO_PARTENZA",
    text: "Ammessa solo la partenza",
  },
  {
    label: "LABEL_RIEPILOGO",
    text: "Riepilogo Prenotazione",
  },
  {
    label: "ROOM_SERVICES",
    text: "Servizi disponibili:",
  },
  {
    label: "NO_SERVICES",
    text: "Nessun servizio disponibile",
  },
  {
    label: "PRICE_FROM",
    text: "A partire da",
  },
  {
    label: "BUTTON_ROOM_INFO_BOOK",
    text: "Info e prenota",
  },
  {
    label: "LABEL_ROOM_REMOVED_APARTMENT",
    text: "La stanza / appartamento ",
  },
  {
    label: "LABEL_RETURN_TO_SEARCH",
    text: "Torna alla ricerca",
  },
  {
    label: "LABEL_YEARS",
    text: "anni",
  },
  {
    label: "LABEL_UNAVAILABLE",
    text: "Non più disponibile",
  },
  {
    label: "LABEL_ROOM_SEARCHED_FOR",
    text: "La stanza ricercata in data dal",
  },
  {
    label: "LABEL_NO_MORE_AVAILABLE",
    text: "non è più disponibile si invita ad effettuare una nuova ricerca.",
  },
  {
    label: "LABEL_PRICE_BOOKING",
    text: "Prezzo del soggiorno:",
  },
  {
    label: "LABEL_DISCOUNT_CODE",
    text: "Codice sconto",
  },
  {
    label: "LABEL_DISCOUNT_AMOUNT",
    text: "Sconto applicato",
  },
  {
    label: "LABEL_YOUR_CART",
    text: "Il tuo carrello",
  },
  {
    label: "LABEL_TAX_PAY_EXCLUDED",
    text: "La tassa di soggiorno non è inclusa nel prezzo",
  },
  {
    label: "SUBTITLE_EXTRAS",
    text: "Rendi il tuo soggiorno indimenticabile, selezionando le tue esperienze preferite",
  },
  {
    label: "LABEL_EXTRA_TOTAL",
    text: "Totale compreso dei servizi aggiuntivi",
  },
  {
    label: "LABEL_NO_ROOM_PAY",
    text: "Impossibile proseguire con l'acquisto in quanto non ci sono stanze o unità abitative disponibili per la prenotazione.",
  },
  {
    label: "LABEL_NO_SEARCH_COMBINATIONS",
    text: "Non sono state trovate combinazioni per la ricerca\
    effettuata, si prega di inserire sul box di sinistra\
    le date del soggiorno, il numero di persone e le\
    stanze richieste.",
  },
  {
    label: "LABEL_PAYMENT_INSERT_YOUR_DATA",
    text: "Inserisci i tuoi dati",
  },
  {
    label: "LABEL_PAYMENT_NO_MANDATORY",
    text: "I campi contrassegnati dall'asterisco sono obbligatori",
  },
  {
    label: "LABEL_PAYMENT_FORM_NAME",
    text: "Nome *",
  },
  {
    label: "LABEL_PAYMENT_FORM_ERROR_NAME",
    text: "Inserire un nome valido",
  },
  {
    label: "LABEL_PAYMENT_FORM_ERROR_SURNAME",
    text: "Inserire un cognome valido",
  },
  {
    label: "LABEL_PAYMENT_FORM_ERROR_EMAIL",
    text: "Inserire una mail valida",
  },
  {
    label: "LABEL_PAYMENT_FORM_ERROR_PHONE",
    text: "Inserire un numero di telefono valido",
  },
  {
    label: "LABEL_PAYMENT_FORM_SURNAME",
    text: "Cognome *",
  },
  {
    label: "LABEL_PAYMENT_FORM_EMAIL",
    text: "E-mail *",
  },
  {
    label: "LABEL_PAYMENT_FORM_CONFIRM_EMAIL",
    text: "Conferma E-mail *",
  },
  {
    label: "LABEL_PAYMENT_FORM_PHONE",
    text: "Numero di cellulare *",
  },
  {
    label: "LABEL_PAYMENT_CONFIRM_FORM_PHONE",
    text: "Conferma Numero di cellulare *",
  },
  {
    label: "LABEL_PAYMENT_FORM_COUNTRY",
    text: "Stato",
  },
  {
    label: "LABEL_PAYMENT_FORM_CITY",
    text: "Città",
  },
  {
    label: "LABEL_PAYMENT_FORM_ZIP_CODE",
    text: "CAP",
  },
  {
    label: "LABEL_PAYMENT_FORM_ADDRESS",
    text: "Indirizzo",
  },
  {
    label: "LABEL_PAYMENT_FORM_NOTES",
    text: "Note / Informazioni aggiuntive (opzionali)",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_METHODS",
    text: "Modalità di pagamento",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_METHODS_1",
    text: "Seleziona una modalità di pagamento per la prenotazione",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_METHODS_2",
    text: "Tipo di pagamento",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_CARD",
    text: "Carta di credito",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_TRANSFER",
    text: "Bonifico bancario",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_CARD_SUBTITLE_1",
    text: "Carta di Credito a Garanzia - sito sicuro",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_CARD_SUBTITLE_2",
    text: `I dettagli della
    tua carta di credito vengono richiesti a garanzia della prenotazione
    e di eventuali extra.`,
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_CARD_SUBTITLE_3",
    text: `si riserva il
    diritto di trattenere temporaneamente un importo pari alla prima
    notte prima del tuo arrivo sotto forma di pre-autorizzazione della
    carta di credito fornita. Consigliamo di utilizzare una carta di
    credito anziché una carta di debito o prepagata per ridurre i tempi
    di gestione bancari del rilascio dell'eventuale pre-autorizzazione.`,
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_1",
    text: `Ho letto e approvo le condizioni di protezione dei dati
    personali riportate `,
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_2",
    text: `nell'informativa`,
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_3",
    text: `Confermando questa prenotazione accetto inoltre i `,
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_4",
    text: `termini generali`,
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_5",
    text: ` e le condizioni di prenotazione sopra elencate per ogni unità
    abitativa.`,
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_6",
    text: `Desidero ricevere informazioni sulle offerte speciali e novità.`,
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_7",
    text: `Ho letto e approvo le condizioni di protezione dei dati personali riportate`,
  },
  {
    label: "LABEL_PAYMENT_FORM_BUTTON_1",
    text: `Conferma Prenotazione`,
  },
  {
    label: "LABEL_PAYMENT_FORM_BUTTON_2",
    text: `Paga Online`,
  },
  {
    label: "LABEL_PAYMENT_FORM_BUTTON_SUBTITLE_1",
    text: `C'è ancora uno step prima del termine della procedura`,
  },
  {
    label: "LABEL_PAYMENT_FORM_ONE_MOMENT",
    text: "Ci vorrà solo un momento",
  },
  {
    label: "BT_TITLE",
    text: "Metodo di pagamento: Bonifico Bancario",
  },
  {
    label: "BT_SUBTITLE",
    text: "Istruzioni:",
  },
  {
    label: "BT_TEXT1",
    text: `Provvedi ad effettuare il pagamento entro i prossimi `,
  },
  {
    label: "BT_TEXT1_5",
    text: ` giorni.
    Non appena riceveremo il bonifico, ti manderemo una conferma via mail con i dettagli del tuo acquisto.`,
  },
  {
    label: "BT_TEXT2",
    text: `Una volta eseguito il bonifico ricordati di salvare una copia della ricevuta e di caricarla
    qui sotto.`,
  },
  {
    label: "BT_TEXT3",
    text: "Importante",
  },
  {
    label: "BT_TEXT4",
    text: `Non controlliamo giornalmente gli accrediti, quindi non dimenticarti di caricare di seguito la ricevuta del bonifico, 
    altrimenti la prenotazione non sarà valida.`,
  },
  {
    label: "BT_TEXT5",
    text: `* Si prega di inserire gli estremi in maniera corretta in particolare la causale per facilitare l'evasione del suo ordine`,
  },
  {
    label: "BT_FORM_1",
    text: "IBAN:",
  },
  {
    label: "BT_FORM_2",
    text: "CAUSALE:",
  },
  {
    label: "BT_FORM_3",
    text: "BENEFICIARIO:",
  },
  {
    label: "BT_FORM_4",
    text: "IMPORTO:",
  },
  {
    label: "BT_FORM_5",
    text: "Carica e invia bonifico",
  },
  {
    label: "BT_PAY_1",
    text: "Rilascia i file qui!",
  },
  {
    label: "BT_PAY_2",
    text: "Trascina e rilascia la tua ricevuta qui!",
  },
  {
    label: "BT_PAY_3",
    text: "O clicca qui per selezionare i file",
  },
  {
    label: "BT_PAY_4",
    text: "Il tuo file è pronto per il caricamento.",
  },
  {
    label: "BT_PAY_5",
    text: `Premi "Carica e invia bonifico" per confermare la prenotazione.`,
  },
  {
    label: "CARD_FORM_1",
    text: "Numero Carta",
  },
  {
    label: "CARD_FORM_2",
    text: "Nome del proprietario della carta",
  },
  {
    label: "CARD_FORM_3",
    text: "Data di scadenza",
  },
  {
    label: "CARD_FORM_4",
    text: "Mese",
  },
  {
    label: "CARD_FORM_5",
    text: "Anno",
  },
  {
    label: "CARD_FORM_6",
    text: "Conferma Prenotazione",
  },
  {
    label: "CARD_FORM_7",
    text: "NOME COMPLETO",
  },
  {
    label: "CARD_FORM_8",
    text: "Scadenza",
  },
  {
    label: "SUCCESS_THANK_YOU",
    text: "Grazie",
  },
  {
    label: "SUCCESS_THANK_YOU_1",
    text: "la tua prenotazione è confermata",
  },
  {
    label: "SUCCESS_THANK_YOU_2",
    text: "Ti è stata inviata una mail all'indirizzo",
  },
  {
    label: "SUCCESS_THANK_YOU_3",
    text: "con tutti i dettagli.",
  },
  {
    label: "SUCCESS_THANK_YOU_4",
    text: "ti aspetta il giorno",
  },
  {
    label: "SUCCESS_THANK_YOU_5",
    text: "Il pagamento",
  },
  {
    label: "SUCCESS_THANK_YOU_6",
    text: "sarà gestito da",
  },
  {
    label: "SUCCESS_THANK_YOU_7",
    text: "Modifica la tua prenotazione o fai una domanda alla struttura in pochi click.",
  },

  {
    label: "SUCCESS_THANK_YOU_8",
    text: "Dettagli prenotazione",
  },
  {
    label: "SUCCESS_THANK_YOU_9",
    text: "La tua prenotazione",
  },
  {
    label: "SUCCESS_THANK_YOU_10",
    text: "Codice della prenotazione",
  },
  {
    label: "SUCCESS_THANK_YOU_11",
    text: "Pin per la modifica",
  },
  {
    label: "SUCCESS_THANK_YOU_12",
    text: "Prenotato a nome di",
  },
  {
    label: "SUCCESS_THANK_YOU_13",
    text: "Gli extra del tuo soggiorno",
  },
  {
    label: "SUCCESS_THANK_YOU_14",
    text: "Quantità:",
  },
  {
    label: "SUCCESS_THANK_YOU_15",
    text: "Tasse",
  },
  {
    label: "SUCCESS_THANK_YOU_16",
    text: "Da pagare in struttura",
  },
  {
    label: "SUCCESS_THANK_YOU_17",
    text: "Il pacchetto che hai scelto",
  },
  {
    label: "SUCCESS_THANK_YOU_18",
    text: "Hai inserito un codice sconto",
  },
  {
    label: "PAYMENT_LOADING_TEXT_1",
    text: "Stiamo contattando la struttura ...",
  },
  {
    label: "PAYMENT_LOADING_TEXT_2",
    text: "Salviamo la tua prenotazione ...",
  },
  {
    label: "PAYMENT_LOADING_TEXT_3",
    text: "Confermiamo lo stato ...",
  },
  {
    label: "ERROR_TITLE",
    text: "Oops.. qualcosa non va",
  },
  {
    label: "ERROR_CODE_5_TEXT",
    text: "Purtroppo una o più stanze della tua soluzione sono state già prenotate. Sarai indirizzato ad una nuova ricerca.",
  },
  {
    label: "ERROR_CODE_GENERIC_TEXT",
    text: "Non è stato possibile creare la prenotazione. Ti consigliamo di contattare la struttura. ",
  },
  {
    label: "LABEL_ERROR_ALERT_1",
    text: "Oops.. La stanza risulta già occupata",
  },
  {
    label: "LABEL_BODY_ERROR_ALERT_1",
    text: "Sembra che tu stia già prenotando una stanza uguale ma la struttura non ha più disponibilità per questa tipologia, prova ad eseguire una nuova ricerca.",
  },
  {
    label: "LABEL_TITLE_ROOM_ADDED_CART",
    text: "La camera è salvata!",
  },
  {
    label: "LABEL_PRIVACY_POLICY",
    text: "Termini e condizioni privacy ( PRIVACY POLICY )",
  },
  {
    label: "LABEL_BOOKING_TERMS",
    text: "Termini e condizioni di prenotazione ( BOOKING TERMS )",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART1",
    text: "Ai sensi dell’articolo 13 del Regolamento (EU) 2016/679 sulla protezione dei dati personali cioè Regolamento, la informiamo che i Suoi dati personali, da lei liberamente conferiti, saranno trattati da F451 S.R.L, di seguito Titolare del trattamento, prevalentemente con mezzi informatici al fine di dar seguito alla sua richiesta di iscrizione alla nostra newsletter automatizzata che le permetterà di essere aggiornato sui servizi da noi offerti e sulle nostre attività promozionali, nonché ci consentirà di conoscere il suo grado di soddisfazione relativamente ai servizi di cui lei ha goduto, soddisfare indagini di mercato ed inviarLe materiale pubblicitario.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART2",
    text: "Le ricordiamo che avrà sempre la possibilità di revocare il consenso a tale iscrizione.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART3",
    text: "Il conferimento dei suoi dati personali è libero tuttavia il mancato conferimento degli stessi non ci permetterà di adempiere a quanto richiesto e, di conseguenza, di iscriverla alla nostra newsletter.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART4",
    text: "Qualora decida di fornirci i suoi dati, la informiamo che questi saranno trattati esclusivamente da personale autorizzato dal titolare e saranno conservati esclusivamente il tempo necessario per perseguire la finalità sopra indicata.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART5",
    text: "In nessun caso i suoi dati saranno diffusi ma potranno essere comunicati a terzi soggetti ai quali il titolare si affida solo ed esclusivamente per garantirle il corretto perseguimento della finalità sopra indicate.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART6",
    text: "Le ricordiamo che le sono garantiti: diritto di accesso, diritto di rettifica, diritto alla cancellazione, diritto alla limitazione, diritto alla portabilità, diritto di revoca del consenso, diritto di reclamo, diritto di opposizione, diritto di non essere sottoposto a una decisione basata unicamente sul trattamento automatizzato, compresa la profilazione ad esclusione dei casi previsti dal Regolamento Per esercitare tali diritti può rivolgersi al Titolare del trattamento scrivendo a F451 S.R.L. - Via Giuseppe Parini 13 oppure inviando un messaggio di posta elettronica all’indirizzo sviluppo@idaitalia.com. Fatto salvo ogni altro ricorso amministrativo o giurisdizionale, lei ha il diritto di proporre reclamo a un’Autorità di controllo, qualora ritenga che il trattamento che la riguarda violi il Regolamento.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_TITLE",
    text: "Newsletter e Marketing",
  },
  {
    label: "ROOM_TREATMENT",
    text: "Trattamento:",
  },
  {
    label: "LABEL_SHOW_MORE",
    text: "Vedi di più",
  },
  {
    label: "LABEL_SAVE_ROOM",
    text: "Aggiungi camera",
  },
  {
    label: "TITLE_CANCELLATION_POLICY_NOREFUNDABLE",
    text: "Cancellation Policy",
  },
  {
    label: "LABEL_CANCELLATION_POLICY_NOREFUNDABLE",
    text: "Addebito dell'intero importo all'atto dell prenotazione senza possibilità di modifica o cancellazione.",
  },
  {
    label: "LABEL_NOREFUNDABLE",
    text: "Non rimborsabile",
  },
  {
    label: "BUTTON_NOREFUNDABLE",
    text: "Non rimborsabile",
  },
  {
    label: "PACKET_STARTING",
    text: "A partire da",
  },
  {
    label: "PACKET_FOR",
    text: "per",
  },
  {
    label: "PACKET_NIGHTS",
    text: "notti.",
  },
  {
    label: "PACKET_SHOW",
    text: "Vedi tutti i pacchetti",
  },
  {
    label: "PACKET_CHOOSE",
    text: "Scegli pacchetto",
  },
  {
    label: "PACKET_BACK",
    text: "Torna Indietro",
  },
  {
    label: "PACKET_CHOOSE_DATE",
    text: "Scegli una data:",
  },
  {
    label: "PACKET_VALID_PERIOD",
    text: "Hai selezionato un periodo valido",
  },
  {
    label: "PACKET_WRONG_PERIOD",
    text: "Hai selezionato un periodo non valido",
  },
  {
    label: "PACKET_SELECT_ROOM",
    text: "Seleziona una stanza",
  },
];
