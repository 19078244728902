export const FR_labels = [
  {
    label: "LABEL_GESTISCI_PRENOTAZIONE",
    text: "Gérer la réservation",
  },
  {
    label: "LABEL_ROOM_NOT",
    text: "Non ",
  },
  {
    label: "LABEL_ROOM_REFUNDABLE",
    text: "Remboursable",
  },
  {
    label: "LABEL_SEARCH_CAMERE",
    text: "Chambres",
  },
  {
    label: "LABEL_SEARCH_SELECT",
    text: "Sélectionner",
  },
  {
    label: "LABEL_SEARCH_TYPE",
    text: "Typologie",
  },
  {
    label: "LABEL_SEARCH_ALL",
    text: "Tous",
  },
  {
    label: "LABEL_SEARCH_ADULTI",
    text: "Adultes",
  },
  {
    label: "LABEL_SEARCH_BAMBINI",
    text: "Enfants",
  },
  {
    label: "LABEL_SEARCH_ROOM_PLACEHOLDER",
    text: "Chambre",
  },
  {
    label: "LABEL_SEARCH_BUTTON",
    text: "Vérifier la disponibilité",
  },
  {
    label: "LABEL_SEARCH_BUTTON_SEARCHING",
    text: "Je cherche ...",
  },
  {
    label: "LABEL_SEARCH_COUPON_CODE",
    text: "J'ai un code promo",
  },
  {
    label: "LABEL_SEARCH_PLACEHOLDER_COUPON",
    text: "Entrez votre code ici",
  },
  {
    label: "LABEL_CALENDAR_ARRIVO",
    text: "Arrivée",
  },
  {
    label: "LABEL_CALENDAR_PARTENZA",
    text: "Départ",
  },
  {
    label: "LABEL_CALENDAR_NOTTI",
    text: "Nuits",
  },
  {
    label: "LABEL_ROOM_DESCR_SHOW_MORE",
    text: "... Voir plus",
  },
  {
    label: "LABEL_ROOM_DESCR_SHOW_LESS",
    text: "... Voir moins",
  },
  {
    label: "LABEL_ROOM_GUESTS",
    text: "Invités",
  },
  {
    label: "LABEL_ROOM_ADDED_CART",
    text: "Votre chambre a été ajoutée au panier",
  },
  {
    label: "LABEL_FROM_DAY",
    text: "à partir du jour",
  },
  {
    label: "LABEL_FROM_DAY_SHORT",
    text: "À partir de",
  },
  {
    label: "LABEL_TO_DAY",
    text: "au",
  },
  {
    label: "LABEL_ROOM_REMOVED",
    text: "n'est plus disponible et a été retirée",
  },
  {
    label: "LABEL_REMOVE_FROM_CART",
    text: "Retirer du panier",
  },
  {
    label: "LABEL_OPS_EMPTY",
    text: "Oops.. c'est vide ici",
  },
  {
    label: "LABEL_ADD_ROOM_PLACEHOLDER",
    text: "Ajoutez une chambre en recherchant celle qui vous convient le mieux...",
  },
  {
    label: "LABEL_TOTAL",
    text: "Total",
  },
  {
    label: "LABEL_GOTO_PAYMENT",
    text: "Aller au paiement",
  },
  {
    label: "LABEL_NO_ROOMS_FOUND",
    text: "Désolé, nous n'avons pas de chambres disponibles pour les dates recherchées.",
  },
  {
    label: "LABEL_NO_ROOMS_GG_MIN_1",
    text: "Désolé, pour les dates sélectionnées, l'établissement exige un séjour minimum de",
  },
  {
    label: "LABEL_NO_ROOMS_GG_MIN_2",
    text: " nuits.",
  },
  {
    label: "LABEL_NO_ROOMS_LAST_MINUTE",
    text: "Désolé, nous n'acceptons pas les réservations de dernière minute. Les réservations pour aujourd'hui sont fermées.",
  },
  {
    label: "LABEL_CONTACT_US",
    text: "Veuillez refaire une recherche ou nous contacter aux coordonnées suivantes :",
  },
  {
    label: "LABEL_SERVICES",
    text: "Services disponibles",
  },
  {
    label: "LABEL_NO_SERVICE",
    text: "Aucun service disponible",
  },
  {
    label: "LABEL_TAX_STAY",
    text: "Taxe de séjour",
  },
  {
    label: "LABEL_CURRENCY_LONG",
    text: "EUR",
  },
  {
    label: "LABEL_STAY_TAX_DESCR",
    text: "par nuit et par invité à régler sur place.",
  },
  {
    label: "LABEL_NO_TAX_TO_PAY",
    text: "Aucune taxe de séjour à payer.",
  },
  {
    label: "LABEL_DESCRIPTION",
    text: "Description",
  },
  {
    label: "CIN_CODE",
    text: "Code CIN :",
  },
  {
    label: "LABEL_BOOKING_TERMS",
    text: "Conditions de réservation et politiques d'annulation",
  },
  {
    label: "LABLE_OCCUPAZIONE_INFERIORE_TITLE",
    text: "Occupation inférieure à la demande",
  },
  {
    label: "LABLE_OCCUPAZIONE_INFERIORE_DESCR",
    text: "L'occupation des chambres sélectionnées est inférieure au nombre de personnes demandé. Procéder quand même au paiement ?",
  },
  {
    label: "LABLE_OCCUPAZIONE_INFERIORE_YES",
    text: "Oui, continuer",
  },
  {
    label: "LABEL_FIXED_COST",
    text: "Frais fixes",
  },
  {
    label: "LABEL_FIXED_COST_INCLUDED",
    text: " (frais fixes inclus)",
  },
  {
    label: "LABEL_TOTAL_BOOKING_ROOM",
    text: "Total du séjour",
  },
  {
    label: "TOOLTIP_ROOM_NOT_REFAUNDABLE",
    text: "Ce tarif offre un prix avantageux, mais ne prévoit aucun remboursement en cas d'annulation, de modification ou de non-présentation.",
  },
  {
    label: "LABEL_PRICE",
    text: "Prix",
  },
  {
    label: "LABEL_ADD_TO_CART",
    text: "Ajouter au panier",
  },
  {
    label: "LABEL_BOOK_NOW",
    text: "Réservez maintenant!",
  },
  {
    label: "LABEL_CHECK_ROOM_CART",
    text: "Souhaitez-vous conserver les chambres dans le panier?",
  },
  {
    label: "LABEL_CHECK_ROOM_CART_YES",
    text: "Oui, enregistrer les chambres",
  },
  {
    label: "LABEL_CHECK_ROOM_CART_NO",
    text: "Non",
  },
  {
    label: "LABEL_MINIMO_NOTTI",
    text: "Nombre minimum de nuits requis",
  },
  {
    label: "LABEL_RESTRIZIONI",
    text: "Arrivée et départ non autorisés",
  },
  {
    label: "LABEL_PERMESSO_ARRIVO",
    text: "Seule l'arrivée est autorisée",
  },
  {
    label: "LABEL_PERMESSO_PARTENZA",
    text: "Seul le départ est autorisé",
  },
  {
    label: "LABEL_RIEPILOGO",
    text: "Récapitulatif de la réservation",
  },
  {
    label: "ROOM_SERVICES",
    text: "Services disponibles :",
  },
  {
    label: "NO_SERVICES",
    text: "Aucun service disponible",
  },
  {
    label: "PRICE_FROM",
    text: "À partir de",
  },
  {
    label: "BUTTON_ROOM_INFO_BOOK",
    text: "Infos et réserver",
  },
  {
    label: "LABEL_ROOM_REMOVED_APARTMENT",
    text: "La chambre / appartement",
  },
  {
    label: "LABEL_RETURN_TO_SEARCH",
    text: "Retour à la recherche",
  },
  {
    label: "LABEL_YEARS",
    text: "ans",
  },
  {
    label: "LABEL_UNAVAILABLE",
    text: "Non disponible",
  },
  {
    label: "LABEL_ROOM_SEARCHED_FOR",
    text: "La chambre recherchée à la date du",
  },
  {
    label: "LABEL_NO_MORE_AVAILABLE",
    text: "n'est plus disponible, veuillez effectuer une nouvelle recherche.",
  },
  {
    label: "LABEL_PRICE_BOOKING",
    text: "Prix du séjour :",
  },
  {
    label: "LABEL_DISCOUNT_CODE",
    text: "Code promo",
  },
  {
    label: "LABEL_DISCOUNT_AMOUNT",
    text: "Réduction appliquée",
  },
  {
    label: "LABEL_YOUR_CART",
    text: "Votre panier",
  },
  {
    label: "LABEL_TAX_PAY_EXCLUDED",
    text: "La taxe de séjour n'est pas incluse dans le prix",
  },
  {
    label: "SUBTITLE_EXTRAS",
    text: "Rendez votre séjour inoubliable en sélectionnant vos expériences préférées",
  },
  {
    label: "LABEL_EXTRA_TOTAL",
    text: "Total, y compris les services supplémentaires",
  },
  {
    label: "LABEL_NO_ROOM_PAY",
    text: "Impossible de poursuivre l'achat car aucune chambre ou unité d'habitation n'est disponible pour la réservation.",
  },
  {
    label: "LABEL_NO_SEARCH_COMBINATIONS",
    text: "Aucune combinaison trouvée pour la recherche effectuée. Veuillez entrer les dates du séjour, le nombre de personnes et les chambres requises dans le champ de gauche.",
  },
  {
    label: "LABEL_PAYMENT_INSERT_YOUR_DATA",
    text: "Saisissez vos informations",
  },
  {
    label: "LABEL_PAYMENT_NO_MANDATORY",
    text: "Les champs marqués d'un astérisque sont obligatoires",
  },
  {
    label: "LABEL_PAYMENT_FORM_NAME",
    text: "Prénom *",
  },
  {
    label: "LABEL_PAYMENT_FORM_ERROR_NAME",
    text: "Veuillez entrer un prénom valide",
  },
  {
    label: "LABEL_PAYMENT_FORM_ERROR_SURNAME",
    text: "Veuillez entrer un nom valide",
  },
  {
    label: "LABEL_PAYMENT_FORM_ERROR_EMAIL",
    text: "Veuillez entrer un e-mail valide",
  },
  {
    label: "LABEL_PAYMENT_FORM_ERROR_PHONE",
    text: "Veuillez entrer un numéro de téléphone valide",
  },
  {
    label: "LABEL_PAYMENT_FORM_SURNAME",
    text: "Nom *",
  },
  {
    label: "LABEL_PAYMENT_FORM_EMAIL",
    text: "E-mail *",
  },
  {
    label: "LABEL_PAYMENT_FORM_CONFIRM_EMAIL",
    text: "Confirmez l'e-mail *",
  },
  {
    label: "LABEL_PAYMENT_FORM_PHONE",
    text: "Numéro de téléphone portable *",
  },
  {
    label: "LABEL_PAYMENT_CONFIRM_FORM_PHONE",
    text: "Confirmez le numéro de téléphone portable *",
  },
  {
    label: "LABEL_PAYMENT_FORM_COUNTRY",
    text: "Pays",
  },
  {
    label: "LABEL_PAYMENT_FORM_CITY",
    text: "Ville",
  },
  {
    label: "LABEL_PAYMENT_FORM_ZIP_CODE",
    text: "Code postal",
  },
  {
    label: "LABEL_PAYMENT_FORM_ADDRESS",
    text: "Adresse",
  },
  {
    label: "LABEL_PAYMENT_FORM_NOTES",
    text: "Notes / Informations supplémentaires (facultatif)",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_METHODS",
    text: "Méthodes de paiement",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_METHODS_1",
    text: "Sélectionnez une méthode de paiement pour la réservation",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_METHODS_2",
    text: "Type de paiement",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_CARD",
    text: "Carte de crédit",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_TRANSFER",
    text: "Virement bancaire",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_CARD_SUBTITLE_1",
    text: "Carte de crédit en garantie - site sécurisé",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_CARD_SUBTITLE_2",
    text: "Les détails de votre carte de crédit sont demandés en garantie de la réservation et d'éventuels extras.",
  },
  {
    label: "LABEL_PAYMENT_FORM_PAYMENT_CARD_SUBTITLE_3",
    text: "se réserve le droit de retenir temporairement un montant équivalent à la première nuit avant votre arrivée sous forme de pré-autorisation de la carte de crédit fournie. Nous recommandons d'utiliser une carte de crédit plutôt qu'une carte de débit ou prépayée pour réduire les délais de traitement bancaire de la libération de l'éventuelle pré-autorisation.",
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_1",
    text: "J'ai lu et j'accepte les conditions de protection des données personnelles indiquées",
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_2",
    text: "dans la déclaration de confidentialité",
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_3",
    text: "En confirmant cette réservation, j'accepte également les",
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_4",
    text: "termes généraux",
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_5",
    text: " et les conditions de réservation énumérées ci-dessus pour chaque unité d'habitation.",
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_6",
    text: "Je souhaite recevoir des informations sur les offres spéciales et les nouveautés.",
  },
  {
    label: "LABEL_PAYMENT_FORM_ACCEPT_7",
    text: "J'ai lu et j'accepte les conditions de protection des données personnelles indiquées.",
  },
  {
    label: "LABEL_PAYMENT_FORM_BUTTON_1",
    text: "Confirmer la réservation",
  },
  {
    label: "LABEL_PAYMENT_FORM_BUTTON_2",
    text: "Payer en ligne",
  },
  {
    label: "LABEL_PAYMENT_FORM_BUTTON_SUBTITLE_1",
    text: "Il reste une étape avant de terminer la procédure",
  },
  {
    label: "LABEL_PAYMENT_FORM_ONE_MOMENT",
    text: "Cela ne prendra qu'un instant",
  },
  {
    label: "BT_TITLE",
    text: "Méthode de paiement : Virement bancaire",
  },
  {
    label: "BT_SUBTITLE",
    text: "Instructions :",
  },
  {
    label: "BT_TEXT1",
    text: "Veuillez effectuer le paiement dans les",
  },
  {
    label: "BT_TEXT1_5",
    text: " prochains jours. Dès réception du virement, nous vous enverrons une confirmation par e-mail avec les détails de votre achat.",
  },
  {
    label: "BT_TEXT2",
    text: "Une fois le virement effectué, pensez à sauvegarder une copie du reçu et à le télécharger ci-dessous.",
  },
  {
    label: "BT_TEXT3",
    text: "Important",
  },
  {
    label: "BT_TEXT4",
    text: "Nous ne vérifions pas quotidiennement les crédits, n'oubliez donc pas de télécharger ci-dessous le reçu du virement, sinon la réservation ne sera pas valide.",
  },
  {
    label: "BT_TEXT5",
    text: "* Veuillez entrer les informations correctement, en particulier la référence, pour faciliter le traitement de votre commande",
  },
  {
    label: "BT_FORM_1",
    text: "IBAN :",
  },
  {
    label: "BT_FORM_2",
    text: "Référence :",
  },
  {
    label: "BT_FORM_3",
    text: "Bénéficiaire :",
  },
  {
    label: "BT_FORM_4",
    text: "Montant :",
  },
  {
    label: "BT_FORM_5",
    text: "Télécharger et envoyer le virement",
  },
  {
    label: "BT_PAY_1",
    text: "Déposez les fichiers ici !",
  },
  {
    label: "BT_PAY_2",
    text: "Faites glisser votre reçu ici !",
  },
  {
    label: "BT_PAY_3",
    text: "Ou cliquez ici pour sélectionner les fichiers",
  },
  {
    label: "BT_PAY_4",
    text: "Votre fichier est prêt à être téléchargé.",
  },
  {
    label: "BT_PAY_5",
    text: 'Appuyez sur "Télécharger et envoyer le virement" pour confirmer la réservation.',
  },
  {
    label: "CARD_FORM_1",
    text: "Numéro de carte",
  },
  {
    label: "CARD_FORM_2",
    text: "Nom du titulaire de la carte",
  },
  {
    label: "CARD_FORM_3",
    text: "Date d'expiration",
  },
  {
    label: "CARD_FORM_4",
    text: "Mois",
  },
  {
    label: "CARD_FORM_5",
    text: "Année",
  },
  {
    label: "CARD_FORM_6",
    text: "Confirmer la réservation",
  },
  {
    label: "CARD_FORM_7",
    text: "NOM COMPLET",
  },
  {
    label: "CARD_FORM_8",
    text: "Expiration",
  },
  {
    label: "SUCCESS_THANK_YOU",
    text: "Merci",
  },
  {
    label: "SUCCESS_THANK_YOU_1",
    text: "votre réservation est confirmée",
  },
  {
    label: "SUCCESS_THANK_YOU_2",
    text: "Un e-mail vous a été envoyé à l'adresse",
  },
  {
    label: "SUCCESS_THANK_YOU_3",
    text: "avec tous les détails.",
  },
  {
    label: "SUCCESS_THANK_YOU_4",
    text: "vous attend le jour",
  },
  {
    label: "SUCCESS_THANK_YOU_5",
    text: "Le paiement",
  },
  {
    label: "SUCCESS_THANK_YOU_6",
    text: "sera géré par",
  },
  {
    label: "SUCCESS_THANK_YOU_7",
    text: "Modifiez votre réservation ou posez une question à l'établissement en quelques clics.",
  },
  {
    label: "SUCCESS_THANK_YOU_8",
    text: "Détails de la réservation",
  },
  {
    label: "SUCCESS_THANK_YOU_9",
    text: "Votre réservation",
  },
  {
    label: "SUCCESS_THANK_YOU_10",
    text: "Code de réservation",
  },
  {
    label: "SUCCESS_THANK_YOU_11",
    text: "Code PIN pour modifications",
  },
  {
    label: "SUCCESS_THANK_YOU_12",
    text: "Réservé au nom de",
  },
  {
    label: "SUCCESS_THANK_YOU_13",
    text: "Les extras de votre séjour",
  },
  {
    label: "SUCCESS_THANK_YOU_14",
    text: "Quantité :",
  },
  {
    label: "SUCCESS_THANK_YOU_15",
    text: "Taxes",
  },
  {
    label: "SUCCESS_THANK_YOU_16",
    text: "À payer sur place",
  },
  {
    label: "SUCCESS_THANK_YOU_17",
    text: "Le forfait que vous avez choisi",
  },
  {
    label: "SUCCESS_THANK_YOU_18",
    text: "Vous avez entré un code promo",
  },
  {
    label: "PAYMENT_LOADING_TEXT_1",
    text: "Nous contactons l'établissement ...",
  },
  {
    label: "PAYMENT_LOADING_TEXT_2",
    text: "Nous sauvegardons votre réservation ...",
  },
  {
    label: "PAYMENT_LOADING_TEXT_3",
    text: "Nous confirmons l'état ...",
  },
  {
    label: "ERROR_TITLE",
    text: "Oops.. quelque chose ne va pas",
  },
  {
    label: "ERROR_CODE_5_TEXT",
    text: "Malheureusement, une ou plusieurs chambres de votre sélection ont déjà été réservées. Vous serez redirigé vers une nouvelle recherche.",
  },
  {
    label: "ERROR_CODE_GENERIC_TEXT",
    text: "La réservation n'a pas pu être créée. Nous vous recommandons de contacter l'établissement.",
  },
  {
    label: "LABEL_ERROR_ALERT_1",
    text: "Oops.. La chambre est déjà occupée",
  },
  {
    label: "LABEL_BODY_ERROR_ALERT_1",
    text: "Il semble que vous réservez déjà une chambre identique, mais l'établissement n'a plus de disponibilité pour ce type. Veuillez effectuer une nouvelle recherche.",
  },
  {
    label: "LABEL_TITLE_ROOM_ADDED_CART",
    text: "La chambre est enregistrée !",
  },
  {
    label: "LABEL_PRIVACY_POLICY",
    text: "Conditions de confidentialité (POLITIQUE DE CONFIDENTIALITÉ)",
  },
  {
    label: "LABEL_BOOKING_TERMS",
    text: "Conditions générales de réservation (CONDITIONS DE RÉSERVATION)",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART1",
    text: "Conformément à l'article 13 du Règlement (UE) 2016/679 sur la protection des données personnelles, nous vous informons que vos données personnelles, librement fournies par vous, seront traitées par F451 S.R.L, ci-après Responsable du traitement, principalement à l'aide d'outils informatiques pour répondre à votre demande d'inscription à notre newsletter automatisée, qui vous permettra de rester informé des services que nous proposons et de nos activités promotionnelles, ainsi que de connaître votre niveau de satisfaction concernant les services dont vous avez bénéficié, de mener des études de marché et de vous envoyer du matériel publicitaire.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART2",
    text: "Nous vous rappelons que vous aurez toujours la possibilité de révoquer votre consentement à cette inscription.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART3",
    text: "Fournir vos données personnelles est facultatif ; cependant, ne pas les fournir nous empêchera de répondre à votre demande et, par conséquent, de vous inscrire à notre newsletter.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART4",
    text: "Si vous décidez de nous fournir vos données, nous vous informons qu'elles seront traitées exclusivement par du personnel autorisé par le Responsable et seront conservées uniquement pendant le temps nécessaire pour atteindre la finalité mentionnée ci-dessus.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART5",
    text: "Vos données ne seront en aucun cas diffusées mais pourront être communiquées à des tiers auxquels le Responsable fait appel uniquement pour garantir la bonne poursuite de la finalité mentionnée ci-dessus.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_PART6",
    text: "Nous vous rappelons que vous bénéficiez des droits suivants : droit d'accès, droit de rectification, droit à l'effacement, droit à la limitation, droit à la portabilité, droit de retirer votre consentement, droit de réclamation, droit d'opposition, droit de ne pas être soumis à une décision fondée uniquement sur un traitement automatisé, y compris le profilage, sauf dans les cas prévus par le Règlement. Pour exercer ces droits, vous pouvez contacter le Responsable du traitement en écrivant à F451 S.R.L. - Via Giuseppe Parini 13 ou en envoyant un e-mail à l'adresse sviluppo@idaitalia.com. Sans préjudice de tout autre recours administratif ou judiciaire, vous avez le droit de déposer une plainte auprès d'une Autorité de contrôle si vous estimez que le traitement vous concernant viole le Règlement.",
  },
  {
    label: "LABEL_NEWSLETTER_TERMS_TITLE",
    text: "Newsletter et Marketing",
  },
  {
    label: "ROOM_TREATMENT",
    text: "Traitement :",
  },
  {
    label: "LABEL_SHOW_MORE",
    text: "Voir plus",
  },
  {
    label: "LABEL_SAVE_ROOM",
    text: "Ajouter une chambre",
  },
  {
    label: "TITLE_CANCELLATION_POLICY_NOREFUNDABLE",
    text: "Politique d'annulation",
  },
  {
    label: "LABEL_CANCELLATION_POLICY_NOREFUNDABLE",
    text: "Montant total facturé au moment de la réservation sans possibilité de modification ou d'annulation.",
  },
  {
    label: "LABEL_NOREFUNDABLE",
    text: "Non remboursable",
  },
  {
    label: "BUTTON_NOREFUNDABLE",
    text: "Non remboursable",
  },
  {
    label: "PACKET_STARTING",
    text: "À partir de",
  },
  {
    label: "PACKET_FOR",
    text: "pour",
  },
  {
    label: "PACKET_NIGHTS",
    text: "nuits.",
  },
  {
    label: "PACKET_SHOW",
    text: "Voir tous les forfaits",
  },
  {
    label: "PACKET_CHOOSE",
    text: "Choisir un forfait",
  },
  {
    label: "PACKET_BACK",
    text: "Retour",
  },
  {
    label: "PACKET_CHOOSE_DATE",
    text: "Choisissez une date :",
  },
  {
    label: "PACKET_VALID_PERIOD",
    text: "Vous avez sélectionné une période valide",
  },
  {
    label: "PACKET_WRONG_PERIOD",
    text: "Vous avez sélectionné une période invalide",
  },
  {
    label: "PACKET_SELECT_ROOM",
    text: "Sélectionnez une chambre",
  },
];
