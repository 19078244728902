import React, { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HorizontalBar from "../layout/horizontalbar";
import structureService from "../../services/structure.service";
import { Button, CircularProgress } from "@mui/material";
import dayjs from "dayjs";
import { it } from "date-fns/locale";
import {
  BsCalendar4Week,
  BsArrowLeft,
  BsCalendar2X,
  BsCalendarCheck,
} from "react-icons/bs";
import PacketRoom from "./PacketRoom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GetLabel } from "../../languages/i18n";

function Packet() {
  const Swal = require("sweetalert2");
  const { id, lang, uuid } = useParams();
  const [language, setLanguage] = React.useState(lang);
  const [isStructureLoading, setStructureLoading] = React.useState(false);
  const [structureInfo, setStructureInfo] = React.useState({
    structure_background: "",
    structure_name: "",
    structure_address: "",
    structure_location: "",
    structure_logo: "",
    structure_phone: "",
    structure_email: "",
    uuid: uuid,
  });
  const [packet, setPacket] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [loadingRooms, setLoadingRooms] = React.useState(true);
  const [loadingButton, setLoadingButton] = React.useState(false);
  const [rooms, setRooms] = React.useState([]);
  const [dates, setDates] = React.useState([]);
  const [id_promo_date, setIdPromoDate] = React.useState();

  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(null);

  const [resetDates, setResetDates] = React.useState([]);
  const [isOpenCalendar, setIsOpenCalendar] = React.useState(false);
  const [isRangeValid, setIsRangeValid] = React.useState(false);
  const pickerRef = useRef();

  const handleClick = (e) => {
    e.preventDefault();
    setIsOpenCalendar(!isOpenCalendar);
  };

  const navigate = useNavigate();

  useEffect(() => {
    getStructureInfo();
    getPacketDetails();
    getPacketDates();
  }, []);

  const getStructureInfo = async () => {
    setStructureLoading(true);
    structureService.getStructureInfo(`${uuid}`).then(
      (result) => {
        result = result.data;
        setStructureInfo(result);
        setStructureLoading(false);
      },
      (error) => {}
    );
  };

  const getPacketDetails = async () => {
    setLoading(true);
    structureService
      .getPacketInfo(lang + "/" + uuid + "/" + id)
      .then((result) => {
        if (result.status === 200) {
          setPacket(result.data);
          setLoading(false);
        }
      });
  };

  const getPacketDates = async () => {
    structureService.getPacketDates(uuid + "/" + id).then(
      (result) => {
        result = result.data;
        setDates(result);
        setResetDates([
          dayjs(result[0].start).toDate(),
          dayjs(result[0].end).toDate(),
        ]);
        setStartDate(dayjs(result[0].start).toDate());
        setEndDate(dayjs(result[0].end).toDate());

        if (
          dayjs(result[0].end).diff(dayjs(result[0].start), "day") <
          packet.min_nights
        ) {
          setIsRangeValid(true);
        } else {
          setIsRangeValid(false);
        }
        getPacketRooms(
          dayjs(result[0].start).format("DD-MM-YYYY"),
          dayjs(result[0].end).format("DD-MM-YYYY")
        );
      },
      (error) => {}
    );
  };

  const getPacketRooms = async (start, end) => {
    setLoadingRooms(true);
    structureService
      .getPacketRooms(lang + "/" + uuid + "/" + id + "/" + start + "/" + end)
      .then((result) => {
        if (result.status === 200) {
          setRooms(result.data);
          setLoadingRooms(false);
        }
      });
  };

  const createBookingPacket = () => {
    setLoadingButton(true);
    structureService
      .createTempBookingPacket(
        uuid +
          "/" +
          language +
          "/" +
          packet.id_promo +
          "/" +
          rooms.find((el) => el.selected).id_promo_cat +
          "/" +
          dayjs(startDate).format("DD-MM-YYYY") +
          "/" +
          dayjs(endDate).format("DD-MM-YYYY")
      )
      .then((result) => {
        if (result.status === 201) {
          navigate("/pay/" + uuid + "/" + result.data.model.booking.link);
        } else {
          setLoadingButton(false);
          Swal.fire({
            title: "Oops..",
            text: "Purtroppo la camera selezionata risulta già piena per il periodo selezionato. Prova a cambiare le date.",
            confirmButtonText: "Ok",
            icon: "error",
          });
        }
      })
      .catch((error) => {
        setLoadingButton(false);
      });
  };
  return (
    <div className="flex flex-col w-full gap-6">
      <HorizontalBar
        structure_name={structureInfo.structure_name}
        structure_address={structureInfo.structure_address}
        structure_location={structureInfo.structure_location}
        structure_logo={structureInfo.structure_logo}
        structure_phone={structureInfo.structure_phone}
        structure_email={structureInfo.structure_email}
        isStructureLoading={isStructureLoading}
        uuid={structureInfo.uuid}
        key={structureInfo.uuid}
        setLanguage={setLanguage}
        language={language}
      ></HorizontalBar>

      <div className="w-full mt-16 max-w-6xl mx-auto px-8 md:px-0">
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {!packet.id_promo ? (
              <div className="flex flex-col gap-2 text-center">
                <div className="text-red-500 text-xl">
                  Il pacchetto selezionato è esaurito oppure è scaduto, prova ad
                  effettuare una nuova ricerca.
                </div>
                <div>
                  <Button
                    onClick={() => {
                      navigate(-1);
                    }}
                    color="error"
                    className="!font-semibold !rounded-full !px-4 !flex !flex-row !items-center !gap-2"
                  >
                    <BsArrowLeft className="w-5 h-5" />
                    Torna Indietro
                  </Button>
                </div>
              </div>
            ) : (
              <div className="flex flex-col gap-10 justify-center">
                <div>
                  <Button
                    onClick={() => {
                      navigate(-1);
                    }}
                    className="!font-semibold !rounded-full !px-4 !flex !flex-row !items-center !gap-2"
                  >
                    <BsArrowLeft className="w-5 h-5" />
                    {GetLabel(language, "PACKET_BACK")}
                  </Button>
                </div>
                <div
                  className="w-full h-80 rounded-md flex animate__animated animate__backInDown bg-cover bg-center"
                  style={{
                    backgroundImage:
                      'url("https://www.idaweb.eu/' + packet.filepath + '")',
                  }}
                ></div>
                <h2 className="text-gray-800 text-5xl font-extrabold">
                  {packet.title}
                </h2>

                <div className="flex flex-col md:flex-row items-start gap-6 mb-20">
                  <p className="text-gray-600 w-full md:w-3/4 flex flex-col gap-4">
                    <div className="text-gray-700 flex flex-row items-center gap-2">
                      <BsCalendar4Week className="w-6 h-6" />{" "}
                      {GetLabel(language, "PACKET_CHOOSE_DATE")}{" "}
                      {/* <div className="packet-datepicker"> */}
                      <DatePicker
                        ref={pickerRef}
                        shouldCloseOnSelect={false}
                        selected={startDate}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        onChange={(dates) => {
                          const [start, end] = dates;
                          setStartDate(start);
                          setEndDate(end);

                          if (
                            dayjs(end).diff(dayjs(start), "day") <
                            packet.min_nights
                          ) {
                            setIsRangeValid(false);
                            return;
                          }
                          if (
                            dayjs(end).diff(dayjs(start), "day") >
                            packet.max_nights
                          ) {
                            setIsRangeValid(false);
                            return;
                          }
                          if (dayjs(start).isValid() && dayjs(end).isValid()) {
                            pickerRef.current.setOpen(false);
                            setIsRangeValid(true);
                            getPacketRooms(
                              dayjs(start).format("DD-MM-YYYY"),
                              dayjs(end).format("DD-MM-YYYY")
                            );
                          }
                        }}
                        includeDateIntervals={dates.map((d) => {
                          d.start = dayjs(d.start).toDate();
                          d.end = dayjs(d.end).toDate();
                          return d;
                        })}
                        dateFormat="dd/MM/yyyy"
                        locale={it}
                        minDate={new Date()}
                      />
                      {/* </div> */}
                      {isRangeValid &&
                      dayjs(startDate).isValid() &&
                      dayjs(endDate).isValid() ? (
                        <span className="text-green-500 flex flex-row items-center ml-auto gap-2">
                          <BsCalendarCheck className="w-6 h-6 " />
                          {GetLabel(language, "PACKET_VALID_PERIOD")}
                        </span>
                      ) : (
                        <span className="text-red-500 flex flex-row items-center ml-auto gap-2">
                          <BsCalendar2X className="w-6 h-6" />
                          {GetLabel(language, "PACKET_WRONG_PERIOD")}
                        </span>
                      )}
                    </div>

                    <div
                      dangerouslySetInnerHTML={{ __html: packet.description }}
                    />

                    <span className="mt-5 font-semibold text-2xl text-gray-800">
                      {GetLabel(language, "PACKET_SELECT_ROOM")}
                    </span>
                    {loadingRooms ? (
                      <CircularProgress />
                    ) : (
                      <>
                        {rooms?.map((room, i) => (
                          <PacketRoom
                            room={room}
                            rooms={rooms}
                            setRooms={setRooms}
                          />
                        ))}

                        {rooms.length === 0 && (
                          <div className="text-red-500">
                            Non ci sono camere libere per il periodo
                            selezionato, scegli altre date.
                          </div>
                        )}
                      </>
                    )}
                  </p>
                  <div className="flex flex-col w-full md:w-1/4">
                    <div
                      className="bg-gray-300 rounded-t-md flex flex-row 
                    items-center px-3 py-2 text-sm"
                    >
                      <div className="flex flex-row items-center gap-1">
                        minimo{" "}
                        <span className="font-semibold text-lg">
                          {packet.min_nights}
                        </span>{" "}
                        notti
                      </div>
                      <div className="flex flex-row items-center ml-auto  gap-1">
                        massimo{" "}
                        <span className="font-semibold text-lg">
                          {packet.max_nights}
                        </span>{" "}
                        notti
                      </div>
                    </div>
                    <div
                      className=" bg-gray-200 py-6 ml-auto text-3xl 
                        font-extrabold flex flex-col gap-1 items-center w-full rounded-md"
                    >
                      <span className="text-gray-500 text-sm font-normal">
                        A partire da
                      </span>
                      {isRangeValid &&
                      dayjs(startDate).isValid() &&
                      dayjs(endDate).isValid() ? (
                        <>
                          {parseFloat(
                            packet.price_night *
                              dayjs(endDate).diff(dayjs(startDate), "day")
                          ).toFixed(2)}
                        </>
                      ) : (
                        "-"
                      )}
                      &euro;
                      <div className="text-gray-500 text-sm font-normal">
                        per{" "}
                        <span className="font-extrabold text-lg text-gray-700">
                          {isRangeValid &&
                          dayjs(startDate).isValid() &&
                          dayjs(endDate).isValid() ? (
                            <>{dayjs(endDate).diff(dayjs(startDate), "day")}</>
                          ) : (
                            "-"
                          )}
                        </span>{" "}
                        notti
                      </div>
                    </div>
                    <button
                      className={`w-full 
                        text-xl font-semibold py-3 px-2 text-center  transition-all rounded-b-md
                        flex flex-row items-center justify-center gap-2
                        ${
                          rooms.length === 0 ||
                          rooms.filter((el) => el.selected).length === 0 ||
                          !dayjs(startDate).isValid() ||
                          !dayjs(endDate).isValid() ||
                          loadingButton ||
                          !isRangeValid ||
                          loadingRooms
                            ? "bg-gray-300 text-gray-400 cursor-not-allowed"
                            : "bg-blue-500 text-white hover:bg-blue-600 cursor-pointer animate__animated animate__tada"
                        } `}
                      onClick={createBookingPacket}
                      disabled={
                        rooms.length === 0 ||
                        rooms.filter((el) => el.selected).length === 0 ||
                        !dayjs(startDate).isValid() ||
                        !dayjs(endDate).isValid() ||
                        loadingButton ||
                        !isRangeValid ||
                        loadingRooms
                      }
                    >
                      {loadingButton && (
                        <div
                          class="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent rounded-full"
                          role="status"
                          aria-label="loading"
                        />
                      )}
                      {loadingButton ? "Sto prenotando..." : "Prenota Ora"}
                    </button>

                    <div className="w-full text-center mt-1">
                      {rooms.length === 0 ||
                      rooms.filter((el) => el.selected).length === 0 ||
                      !dayjs(startDate).isValid() ||
                      !dayjs(endDate).isValid() ||
                      !isRangeValid ||
                      loadingRooms ? (
                        <p className="text-sm text-red-500">
                          Devi scegliere almeno una stanza e un periodo valido
                        </p>
                      ) : (
                        <p className="text-md mt-2 text-green-500 font-semibold">
                          Puoi prenotare!
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Packet;
