import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./MyCalendar.css"; // Importiamo il file CSS per personalizzare l'aspetto
import structureService from "../services/structure.service";
import moment from "moment";
import { FaArrowRightFromBracket } from "react-icons/fa6";
import { FaArrowRightToBracket } from "react-icons/fa6";
import { GetLabel } from "../languages/i18n";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Select } from "antd";
import { Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import DynamicHeroIcon from "../components/heroicons/hicon";
import { TextField } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoadingButton } from "@mui/lab";
import useStateCallback from "../components/useStateCallback";

const MyCalendar = ({
  uuid,
  getRooms,
  isSearching,
  promoCode,
  handlePromoCodeChange,
  language,
  searchBase,
  setSearchBase,
}) => {
  const [date, setDate] = useState([new Date(), new Date()]);
  const [availabilityData, setAvailabilityData] = useState([]);
  const [hoverDate, setHoverDate] = useState(null);
  const [activeStartDate, setActiveStartDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [rooms, setRooms] = useStateCallback(searchBase.rooms);
  const [roomsType, setRoomsType] = React.useState([]);
  const [dateRange, setDateRange] = useState([
    parseDate(searchBase.date_from),
    parseDate(searchBase.date_to),
  ]);
  useEffect(() => {
    if (uuid) {
      getRoomsType();
    }
  }, [uuid]);

  useEffect(() => {}, [roomsType]);
  const getRoomsType = async () => {
    if (!uuid) {
      return;
    }
    try {
      const result = await structureService.getRoomsType(uuid, language);
      const roomsTypeData = result.data; // Dati delle stanze ottenuti
      setRoomsType(roomsTypeData); // Aggiorno lo stato delle stanze
    } catch (error) {
      setRoomsType([]); // Resetta lo stato in caso di errore
    }
  };

  // Funzione per mappare i tipi di stanza in un formato compatibile con Select
  const getType = () => {
    const emptyOption = {
      value: "",
      label: GetLabel(language, "LABEL_SEARCH_ALL"),
    };
    const options = roomsType.map((roomType) => ({
      value: roomType.value,
      label: roomType.text,
    }));

    return [emptyOption, ...options]; // Unisce l'opzione vuota con le stanze
  };
  function parseDate(dateString) {
    if (!dateString) return null; // Gestione del caso in cui la stringa non sia definita

    if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
      return new Date(dateString); // Ritorna direttamente una Date valida
    }

    const [day, month, year] = dateString.split("/").map(Number); // Divide e converte in numeri
    return new Date(year, month - 1, day); // Crea l'oggetto Date (mese da 0 a 11)
  }
  const handleDateChange = (value) => {
    // Controllo se i valori sono validi
    if (!value[0] || !value[1]) {
      setIsDisabled(true);
      return;
    }
    setIsDisabled(false);

    // Funzione per formattare le date nel formato DD/MM/YYYY
    const formatDateToString = (date) => {
      const day = String(date.getDate()).padStart(2, "0"); // Aggiunge uno zero iniziale se necessario
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Mesi da 0 a 11, quindi aggiungiamo 1
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };

    // Aggiorna searchBase con i nuovi valori nel formato richiesto
    const dateFrom = value[0];
    const dateTo = value[1];

    setSearchBase({
      ...searchBase,
      date_from: formatDateToString(dateFrom), // Formattato come DD/MM/YYYY
      date_to: formatDateToString(dateTo), // Formattato come DD/MM/YYYY
    });

    // Aggiorna il range nel formato oggetto Date
    setDateRange([new Date(dateFrom), new Date(dateTo)]);
  };
  // const handleDateChange = (value) => {
  //   if (value[0].toISOString() === null || value[1].toISOString() === null)
  //     setIsDisabled(true);
  //   else setIsDisabled(false);
  //   // Aggiorna searchBase con i nuovi valori
  //   const dateFrom = new Date(value[0]);
  //   const dateTo = new Date(value[1]);
  //   setSearchBase({
  //     ...searchBase,
  //     date_from: dateFrom,
  //     date_to: dateTo,
  //   });
  //   setDateRange(value);
  // };
  const setSearchBaseRooms = (value) => {
    setSearchBase({
      ...searchBase,
      rooms: value,
    });
  };

  const getAvailabilityData = async () => {
    try {
      setLoading(true);
      const currentStartDate = activeStartDate || new Date();

      const dateFrom = moment(currentStartDate)
        .startOf("month")
        .format("DD/MM/YYYY");
      const dateTo = moment(currentStartDate)
        .endOf("month")
        .format("DD/MM/YYYY");

      // Effettua la chiamata al backend
      const response = await structureService.getAvailability({
        date_from: dateFrom,
        date_to: dateTo,
        lang: "it",
        uuid: uuid, // o altra lingua in base alla tua configurazione
      });

      // Imposta i dati di disponibilità nel tuo stato
      setAvailabilityData(response.data);
    } catch (error) {
      console.error("Errore nel caricamento dei dati di disponibilità:", error);
    } finally {
      setLoading(false); // Finito il caricamento
    }
  };
  useEffect(() => {
    getRoomsType();
  }, []);
  useEffect(() => {
    getRoomsType();
  }, [language]);

  useEffect(() => {
    if (activeStartDate && uuid) {
      getAvailabilityData();
    }
  }, [activeStartDate, uuid]);

  const handleActiveStartDateChange = ({ activeStartDate }) => {
    setActiveStartDate(activeStartDate); // Aggiorna la data di inizio attiva per il nuovo mese
  };

  const disableAllDates = () => {
    return true; // Restituisce true per disabilitare tutte le date
  };

  const tileDisabled = ({ date }) => {
    if (loading) {
      return true; // Se è in caricamento, disabilita tutte le date
    } else if (availabilityData.length === 0) {
      return disableAllDates();
    }

    return disablePastDates({ date }); // Disabilita solo le date passate quando non c'è il caricamento
  };

  const disablePastDates = ({ date }) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Imposta l'ora a mezzanotte per evitare problemi con i millisecondi
    return date < today; // Disabilita le date passate
  };

  const isDateInRange = (date) => {
    if (!dateRange || dateRange.length !== 2) return false;
    return date >= dateRange[0] && date <= dateRange[1];
  };
  // Funzione per verificare la disponibilità per un giorno specifico
  const getDayClassName = (date) => {
    const dateString = moment(date).format("YYYY-MM-DD"); // Usa moment per garantire il formato corretto
    const dayData = availabilityData.find((item) => item.date === dateString);

    let classNames = [];
    if (isDateInRange(date)) classNames.push("in-range"); // Evidenzia le date nel range
    if (!dayData) return classNames.join(" ");

    if (!dayData.availableForNightStay) classNames.push("available-night-stay");
    return classNames.join(" ");

    // if (!dayData) return ""; // Se non ci sono dati per il giorno, non fare nulla
    // let classNames = [];
    // if (!dayData.availableForNightStay) classNames.push("available-night-stay");

    // return classNames.join(" "); // Ritorna le classi CSS separate da uno spazio
  };

  const renderMinimumStay = ({ date }) => {
    const dateString = moment(date).format("YYYY-MM-DD"); // Usa moment per garantire il formato corretto
    const dayData = availabilityData.find((item) => item.date === dateString);
    if (!dayData) return <div className="no-min-stay-text"></div>;
    else if (
      dayData.minimumStay == 1 &&
      !dayData.availableForCheckIn &&
      !dayData.availableForCheckOut
    )
      return <div className="no-min-stay-text"></div>;
    else if (!dayData.availableForNightStay)
      return <div className="no-min-stay-text"></div>;

    return (
      <div className="day-content">
        {/* Giorni minimi sempre centrati */}
        <div
          className="min-stay-text"
          title={
            dayData.minimumStay > 1
              ? `Soggiorno minimo di ${dayData.minimumStay} notti`
              : ""
          }
        >
          {dayData.minimumStay > 1 && dayData.availableForNightStay
            ? `${dayData.minimumStay} min`
            : ""}
        </div>
        {dayData.availableForCheckIn && dayData.availableForCheckOut ? (
          <div
            className="restriction-check-in-out"
            title="Checkin non permesso. Checkout non permesso"
          >
            R
          </div>
        ) : (
          <div className="arrows-container">
            {dayData.availableForCheckIn && (
              <div className="check-in-arrow" title="Ammessa solo la partenza">
                <FaArrowRightFromBracket
                  style={{ fontWeight: 200, fontSize: "11px" }}
                />
              </div>
            )}

            {dayData.availableForCheckOut && (
              <div className="check-out-arrow" title="Ammesso solo l'arrivo">
                <FaArrowRightToBracket
                  style={{ fontWeight: 200, fontSize: "11px" }}
                />
              </div>
            )}
          </div>
        )}
        {/* Icone di arrivo e partenza */}
      </div>
    );
  };

  const formatDate = (date) => {
    // Ottieni il giorno della settimana e il giorno del mese
    const dayOfWeek = date.toLocaleDateString("it-IT", { weekday: "short" }); // "sab"
    const dayOfMonth = date.getDate(); // 26
    const month = date.toLocaleDateString("it-IT", { month: "short" }); // "apr"

    // Restituisci il formato desiderato
    return (
      <span>
        <b>{`${dayOfWeek} ${dayOfMonth}`}</b> {month}
      </span>
    );
  };

  const calculateNights = (startDate, endDate) => {
    if (!startDate || !endDate) return 0; // Controllo per date non valide

    // Calcola la differenza in millisecondi
    const differenceInTime = endDate.getTime() - startDate.getTime();

    // Converte i millisecondi in giorni
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    // Restituisce il numero di notti come intero
    return Math.max(0, Math.floor(differenceInDays));
  };
  const Search = () => {
    if (searchBase.rooms.length > 0) {
      getRooms(true);
    }
  };
  const changeRoomsNumber = (e) => {
    const currentVal = e;
    var nrooms = [];
    if (currentVal > nrooms.length) {
      for (let nr = 0; nr < currentVal; nr++) {
        nrooms.push({
          id: nr,
          name: "Camera " + (nr + 1),
          adults: nr > 0 ? 1 : 2,
          childs: 0,
        });
      }
    } else if (currentVal < nrooms.length) {
      // Se la selezione è minore, rimuovi le camere in eccesso
      nrooms = nrooms.slice(0, currentVal);
    }
    setSearchBaseRooms(nrooms);
  };
  const changeRoomsType = (e) => {
    const currentVal = e;
    setSearchBase({
      ...searchBase,
      rooms_type: currentVal, // Formattato come DD/MM/YYYY
    });
  };
  const setRoomAdults = (e, id) => {
    const newRooms = searchBase.rooms.map((room) => {
      if (room.id === id) {
        return { ...room, adults: e.target.value };
      }
      return room;
    });
    setSearchBaseRooms(newRooms);
  };
  const setRoomChildren = (e, id) => {
    const newRooms = searchBase.rooms.map((room) => {
      if (room.id === id) {
        return { ...room, childs: parseInt(e.target.value) };
      }
      return room;
    });
    setSearchBaseRooms(newRooms);
  };
  function getArray() {
    return [...Array(99)].map((e, i) => {
      return {
        value: i + 1,
        label: i + 1,
      };
    });
  }

  return (
    <div>
      <Calendar
        onChange={handleDateChange}
        onActiveStartDateChange={handleActiveStartDateChange}
        value={dateRange}
        // value={date}
        selectRange={true}
        showDoubleView={false}
        prev2Label={null}
        next2Label={null}
        tileClassName={({ date, view }) => getDayClassName(date)} // Aggiungi classi personalizzate ai giorni
        tileContent={renderMinimumStay}
        tileDisabled={tileDisabled}
        locale={language}
      />
      <div className="calendar-legend">
        <div className="flex flex-row">
          <p className="w-full">
            <span style={{ fontSize: "11px", color: "red", fontWeight: "700" }}>
              [min]
            </span>{" "}
            <span style={{ fontSize: "11px" }}>
              {GetLabel(language, "LABEL_MINIMO_NOTTI")}
            </span>
          </p>
          <p className="w-full">
            <span style={{ fontSize: "11px", color: "red", fontWeight: "700" }}>
              [R]
            </span>{" "}
            <span style={{ fontSize: "11px" }}>
              {GetLabel(language, "LABEL_RESTRIZIONI")}
            </span>
          </p>
        </div>
        <div className="flex flex-row" style={{ padding: "2px" }}>
          <p className="w-full flex flex-row gap-4">
            <FaArrowRightToBracket
              style={{
                fontWeight: 200,
                fontSize: "11px",
                color: "red",
                marginLeft: "5px",
              }}
            />
            <span style={{ fontSize: "11px" }}>
              {GetLabel(language, "LABEL_PERMESSO_ARRIVO")}
            </span>
          </p>
          <p className="w-full flex flex-row gap-2">
            <FaArrowRightFromBracket
              style={{
                fontWeight: 200,
                fontSize: "11px",
                color: "red",
                marginLeft: "2px",
              }}
            />{" "}
            <span style={{ fontSize: "11px" }}>
              {GetLabel(language, "LABEL_PERMESSO_PARTENZA")}
            </span>
          </p>
        </div>
      </div>
      <div
        style={{
          borderTop: "1px solid lightGrey",
          marginBottom: "15px",
        }}
      ></div>
      <div
        className="eno9zd51 ltr-2nsdlj e3a2zab1"
        style={{
          paddingLeft: "10px",
          paddingRight: "10px",
        }}
      >
        <div className="esso1sk3 ltr-w2847c e3a2zab1">
          <p className="esso1sk2  ltr-epnzd2 e7unl6w0">
            <b>{GetLabel(language, "LABEL_CALENDAR_ARRIVO")}</b>
          </p>
          <div className="ltr-1vo9pet esso1sk1">
            <div className="ltr-199jhfg e3a2zab1"></div>
            <p className="esso1sk0  ltr-sgnors e7unl6w0">
              {formatDate(dateRange[0])}
            </p>
          </div>
        </div>
        <div className="eno9zd50 ltr-i28wbi e3a2zab1"></div>
        <div className="esso1sk3 ltr-w2847c e3a2zab1">
          <p className="esso1sk2  ltr-epnzd2 e7unl6w0">
            <b>{GetLabel(language, "LABEL_CALENDAR_PARTENZA")}</b>
          </p>
          <div className="ltr-1vo9pet esso1sk1">
            <div className="ltr-199jhfg e3a2zab1"></div>
            <p className="esso1sk0  ltr-sgnors e7unl6w0">
              {formatDate(dateRange[1])}
            </p>
          </div>
        </div>
        <div className="eno9zd50 ltr-i28wbi e3a2zab1"></div>
        <div className="esso1sk3 ltr-w2847c e3a2zab1">
          <p className="esso1sk2  ltr-epnzd2 e7unl6w0">
            <b>{GetLabel(language, "LABEL_CALENDAR_NOTTI")}</b>
          </p>
          <div className="ltr-1vo9pet esso1sk1">
            <div className="ltr-199jhfg e3a2zab1"></div>
            <p
              className="esso1sk0  ltr-sgnors e7unl6w0"
              style={{ width: "30px" }}
            >
              {calculateNights(dateRange[0], dateRange[1])}
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          borderTop: "1px solid lightGrey",
          marginTop: "15px",
          marginBottom: "25px",
        }}
      ></div>
      <Grid2 container spacing={0} sx={{ mt: 2, marginLeft: "10px" }}>
        <Grid2
          item
          xs={4}
          sx={{
            display: "flex",
            justifyContent: "flex-start", // Allinea la scritta a sinistra
            alignItems: "center", // Centra verticalmente la scritta
            fontSize: "15px",
            color: "rgb(80, 85, 101)",
            fontWeight: "500",
            marginTop: "17px",
          }}
        >
          {/* Seleziona */}
          {/*{GetLabel(language, "LABEL_SEARCH_CAMERE")}*/}
          {GetLabel(language, "LABEL_SEARCH_SELECT")}
        </Grid2>
        <Grid2
          item
          xs={8}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid2
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "48%",
            }}
          >
            {roomsType.length > 1 && getType()?.length > 0 && (
              <>
                <label
                  style={{
                    fontSize: "14px",
                    color: "rgb(80, 85, 101)",
                    fontWeight: "500",
                  }}
                >
                  {GetLabel(language, "LABEL_SEARCH_TYPE")}
                </label>
                <Select
                  style={{ width: 120 }}
                  options={getType()}
                  onChange={changeRoomsType}
                  disabled={isSearching}
                  className="rounded-input"
                />
              </>
            )}
          </Grid2>
          <Grid2
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "48%",
            }}
          >
            <label
              style={{
                fontSize: "15px",
                color: "rgb(80, 85, 101)",
                fontWeight: "500",
              }}
            >
              {GetLabel(language, "LABEL_SEARCH_CAMERE")}
            </label>
            <Select
              defaultValue="1"
              style={{
                width: 120,
              }}
              options={getArray()}
              value={searchBase.rooms.length}
              onChange={changeRoomsNumber}
              disabled={isSearching}
              className="rounded-input"
            />
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2 item xs={6}></Grid2>
      <Grid2 item xs={12}>
        {searchBase.rooms.map((room, index) => {
          return (
            <Grid2 container spacing={0} sx={{ mt: 2, marginLeft: "10px" }}>
              <Grid2
                item
                xs={4}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start", // Allinea la scritta a sinistra
                  alignItems: "center", // Centra verticalmente la scritta
                  fontSize: "15px",
                  color: "rgb(80, 85, 101)",
                  fontWeight: "500",
                  marginTop: "20px",
                }}
              >
                {GetLabel(language, "LABEL_SEARCH_ROOM_PLACEHOLDER")}{" "}
                {index + 1}
              </Grid2>

              <Grid2
                item
                xs={8}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid2
                  item
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "48%",
                  }}
                >
                  <label
                    style={{
                      fontSize: "14px",
                      color: "rgb(80, 85, 101)",
                      fontWeight: "500",
                    }}
                  >
                    {GetLabel(language, "LABEL_SEARCH_ADULTI")}
                  </label>
                  <TextField
                    type="number"
                    InputProps={{
                      inputProps: {
                        max: 100,
                        min: searchBase.rooms.length === 1 ? 1 : 0,
                      },
                    }}
                    size="small"
                    onChange={(e) => setRoomAdults(e, room.id)}
                    value={room.adults}
                    disabled={isSearching}
                    className="rounded-input"
                    style={{ padding: "4.5px 14px !important" }}
                  />
                </Grid2>
                <Grid2
                  item
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "48%",
                  }}
                >
                  <label
                    style={{
                      fontSize: "14px",
                      color: "rgb(80, 85, 101)",
                      fontWeight: "500",
                    }}
                  >
                    {GetLabel(language, "LABEL_SEARCH_BAMBINI")}
                  </label>
                  <TextField
                    type="number"
                    InputProps={{
                      inputProps: {
                        max: 100,
                        min: 0,
                      },
                    }}
                    size="small"
                    onChange={(e) => setRoomChildren(e, room.id)}
                    value={
                      Array.isArray(room.childs)
                        ? room.childs.length
                        : parseInt(room.childs)
                    }
                    disabled={isSearching}
                    className="rounded-input"
                    style={{ padding: "4.5px 14px !important" }}
                  />
                </Grid2>
              </Grid2>
            </Grid2>
          );
        })}
      </Grid2>
      <Grid2 item xs={12}>
        <LoadingButton
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          className="btn-search"
          loading={isSearching}
          onClick={Search}
          loadingPosition="start"
          disabled={
            // !isDisabled
            //   ? searchBase.rooms?.filter((el) => el.adults > 0).length >= 1
            //     ? false
            //     : true
            //   : isDisabled
            false
          }
        >
          {isSearching === false
            ? GetLabel(language, "LABEL_SEARCH_BUTTON")
            : GetLabel(language, "LABEL_SEARCH_BUTTON_SEARCHING")}
        </LoadingButton>
      </Grid2>
      {/* <Button onClick={Search}>Verifica</Button> */}
      <Grid2 item xs={12}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              sx={{
                display: "flex",
                gap: "10px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DynamicHeroIcon
                icon={"TicketIcon"}
                className="promo-icon"
              ></DynamicHeroIcon>{" "}
              {GetLabel(language, "LABEL_SEARCH_COUPON_CODE")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              size="small"
              onChange={handlePromoCodeChange}
              value={promoCode}
              disabled={isSearching}
              className="rounded-input"
              placeholder={GetLabel(
                language,
                "LABEL_SEARCH_PLACEHOLDER_COUPON"
              )}
              sx={{ width: "100%" }}
            />
          </AccordionDetails>
        </Accordion>
        <img
          src="https://d3jrq3tjjnb829.cloudfront.net/1530029297_comodo-secure-logo-new.png"
          className="sponsor"
        ></img>
      </Grid2>
    </div>
  );
};

export default MyCalendar;
