import * as React from "react";
import Grid from "@mui/material/Grid";
import HorizontalBar from "../layout/horizontalbar";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Flickity from "react-flickity-component";
import Ota from "./ota";
import "../../flickity.css";
import DynamicHeroIcon from "../heroicons/hicon";
import { BiBath } from "react-icons/bi";
import Button from "@mui/material/Button";
import { useSpring, animated } from "react-spring"; // Using hooks in V9
import { useRef } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RoomDetails from "../room_details/room_details";
import { GetLabel } from "../../languages/i18n";
import Packets from "../packets/Packets";
import NotRefundable from "./NotRefundable";
import RoomOptions from "./RoomOptions";
import { MdKeyboardArrowDown } from "react-icons/md";
import SizeContext from "antd/es/config-provider/SizeContext";
import ReactTextCollapse from "react-text-collapse/dist/ReactTextCollapse";
import "react-image-lightbox/style.css";
import Lightbox from "react-image-lightbox";
import { useMediaQuery } from "@mui/material";
import structureService from "../../services/structure.service";

const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => Icons[icon]);

library.add(...iconList);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{ p: 3 }}
          style={{ paddingLeft: "12px", paddingRight: "12px" }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Room(props) {
  const [value, setValue] = React.useState(0);
  const [showRoomDetails, setShowRoomDetails] = React.useState(false);
  const [focusedRoom, setFocusedRoom] = React.useState({});
  const [openNotRefundable, setOpenNotRefundable] = React.useState(false);
  const [priceRoom, setPriceRoom] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(false); // Controlla se la galleria è aperta
  const [photoIndex, setPhotoIndex] = React.useState(0); // Indice dell'immagine selezionata
  const [cancellationRules, setCancellationRules] = React.useState("");
  const panelRef = useRef(null);
  const marginLeftSm = "20px !important";
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleButtonClick = (price) => {
    setOpenNotRefundable(!openNotRefundable);
    setPriceRoom(price);

    if (panelRef.current) {
      panelRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };

  const TEXT_COLLAPSE_OPTIONS = {
    collapse: false, // default state when component rendered
    collapseText: GetLabel(props.language, "LABEL_ROOM_DESCR_SHOW_MORE"), // text to show when collapsed
    expandText: GetLabel(props.language, "LABEL_ROOM_DESCR_SHOW_LESS"), // text to show when expanded
    minHeight: 100, // component height when closed
    maxHeight: 350, // expanded to
  };
  React.useEffect(() => {
    // Se la stanza è non rimborsabile, imposta priceRoom sul prezzo della stanza
    if (focusedRoom.isNotRefundable) {
      setPriceRoom(focusedRoom.price);
    }
  }, [focusedRoom]);
  const style = useSpring({
    from: { opacity: 0, marginTop: 100 },
    to: { opacity: 1, marginTop: 0 },
  });
  const fetchCancellactionRules = async () => {
    structureService.getCancellationRules(props.uuid, props.language).then(
      (result) => {
        result = result.data;
        setCancellationRules(result);
      },
      (error) => {}
    );
  };
  const ShowRoomDetails = (e, room) => {
    setFocusedRoom(room);
    setShowRoomDetails(true);
    fetchCancellactionRules();
  };
  const isMobile = useMediaQuery("(max-width:767px)");

  return (
    <>
      {props.isSearching === false && (
        <>
          <animated.div style={style} className="room">
            <RoomDetails
              addCart={props.addCart}
              room={focusedRoom}
              open={showRoomDetails}
              setOpen={setShowRoomDetails}
              uuid={props.uuid}
              tax={props.tax}
              nrooms={props.nrooms}
              language={props.language}
              cancellationRules={cancellationRules}
            ></RoomDetails>
            {props.rooms.map((room, index_room) => {
              return (
                <TabPanel
                  ref={panelRef}
                  value={value}
                  index={room.index}
                  className={`room-panel  ${
                    // className={`room-panel rounded-tr-lg ${
                    // !openNotRefundable && "!rounded-b-lg"
                    !openNotRefundable
                  }`}
                  style={{
                    position: "relative",
                  }}
                >
                  {room.isNotRefundable && (
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        right: "3.7rem",
                        fontWeight: "bold",
                        padding: "10px 15px 16px",
                        fontSize: "12px",
                        textTransform: "uppercase",
                        clipPath:
                          "polygon(0 0, 100% 0, 100% 85%, 50% 100%, 0 85%)",
                        zIndex: 1000, // Assicura che il badge sia sopra la card
                        textAlign: "center",
                      }}
                      class=" bg-purple-500 text-white"
                    >
                      {GetLabel(props.language, "LABEL_ROOM_NOT")} <br></br>
                      {GetLabel(props.language, "LABEL_ROOM_REFUNDABLE")}
                    </div>
                  )}
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      height: "auto",
                    }}
                  >
                    <Grid item sm={9} xs={12}>
                      <Grid container>
                        <Grid
                          item
                          sm={4}
                          xs={12}
                          sx={{ marginBottom: { sm: "0", xs: "10px" } }}
                        >
                          <div>
                            <Flickity options={{ lazyLoad: true }}>
                              {room.images.map((image, i) => {
                                return (
                                  <div
                                    key={i}
                                    onClick={() => {
                                      setPhotoIndex(i); // Imposta l'immagine selezionata
                                      setIsOpen(true); // Mostra la galleria
                                    }}
                                    style={{
                                      background: `url(${image.image})`,
                                      backgroundSize: "cover",
                                      backgroundPosition: "center",
                                      height: "200px", // Altezza personalizzabile
                                    }}
                                    className="carousel-img"
                                  ></div>
                                );
                              })}
                            </Flickity>
                            {isOpen && (
                              <Lightbox
                                mainSrc={room.images[photoIndex].image} // Immagine attuale
                                nextSrc={
                                  room.images[
                                    (photoIndex + 1) % room.images.length
                                  ].image
                                } // Immagine successiva
                                prevSrc={
                                  room.images[
                                    (photoIndex + room.images.length - 1) %
                                      room.images.length
                                  ].image
                                } // Immagine precedente
                                onCloseRequest={() => setIsOpen(false)} // Chiude la galleria
                                onMovePrevRequest={() =>
                                  setPhotoIndex(
                                    (photoIndex + room.images.length - 1) %
                                      room.images.length
                                  )
                                } // Sposta all'immagine precedente
                                onMoveNextRequest={() =>
                                  setPhotoIndex(
                                    (photoIndex + 1) % room.images.length
                                  )
                                } // Sposta all'immagine successiva
                              />
                            )}
                          </div>
                        </Grid>
                        <Grid item sm={8} xs={12}>
                          <Box
                            sx={{
                              marginLeft: { sm: "10px", xs: "0" },
                              marginBottom: {
                                sm: "10px !important",
                                xs: "0",
                              },
                            }}
                          >
                            <Ota ota={room.ota}></Ota>
                          </Box>
                          <Typography
                            variant="h2"
                            gutterBottom
                            component="div"
                            className="room-name"
                            sx={{
                              marginLeft: { sm: marginLeftSm, xs: "0" },
                              marginTop: {
                                sm: "0 !important",
                                xs: "20px !important",
                              },
                            }}
                          >
                            {room.roomName}
                          </Typography>
                          <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <Typography
                              variant="div"
                              gutterBottom
                              component="div"
                              className="room-details"
                              sx={{
                                display: "flex",
                                marginLeft: { sm: marginLeftSm, xs: "0" },
                                marginTop: {
                                  sm: "5px !important",
                                  xs: "20px !important",
                                },
                                fontSize: "0.75rem !important",
                              }}
                            >
                              <span className="room-details-guests">
                                {GetLabel(props.language, "LABEL_ROOM_GUESTS")}:{" "}
                              </span>
                              {room.guests >= 6 ? (
                                <>
                                  {room.guests}
                                  <DynamicHeroIcon
                                    icon="UserIcon"
                                    className="room-details-icon"
                                  ></DynamicHeroIcon>
                                </>
                              ) : (
                                [...Array(room.guests)].map((e, i) => (
                                  <DynamicHeroIcon
                                    icon="UserIcon"
                                    className="room-details-icon"
                                  ></DynamicHeroIcon>
                                ))
                              )}
                              {room.mq_raw ? (
                                <>
                                  <span
                                    style={{
                                      marginLeft: "15px",
                                      marginRight: "15px",
                                      color: "#adadad",
                                    }}
                                  >
                                    |
                                  </span>
                                  <DynamicHeroIcon
                                    icon="CubeTransparentIcon"
                                    className="room-details-icon room-details-mq-icon"
                                  ></DynamicHeroIcon>
                                  <span>{room.mq_raw}m²</span>
                                </>
                              ) : (
                                ""
                              )}
                              {room.n_bathroom ? (
                                <>
                                  <span
                                    style={{
                                      marginLeft: "15px",
                                      marginRight: "15px",
                                      color: "#adadad",
                                    }}
                                  >
                                    |
                                  </span>
                                  <BiBath className="room-details-icon room-details-mq-icon"></BiBath>
                                  <span>{room.n_bathroom}</span>
                                </>
                              ) : (
                                ""
                              )}
                            </Typography>
                          </Box>
                          <Typography
                            variant="p"
                            gutterBottom
                            component="div"
                            className="room-description"
                            sx={{ marginLeft: { sm: marginLeftSm, xs: "0" } }}
                          >
                            {room !== undefined &&
                            room.description !== undefined ? (
                              room !== null &&
                              room.description !== null &&
                              room.description.length > 500 ? (
                                <div>
                                  <ReactTextCollapse
                                    options={TEXT_COLLAPSE_OPTIONS}
                                  >
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: room.description,
                                      }}
                                    />
                                  </ReactTextCollapse>{" "}
                                  <br></br>
                                  {room !== undefined &&
                                  room.CIN_code !== null &&
                                  room.CIN_code !== "" ? (
                                    <div style={{ marginTop: "8px" }}>
                                      <div>
                                        <b>
                                          {GetLabel(props.language, "CIN_CODE")}
                                        </b>{" "}
                                        {room.CIN_code}
                                      </div>
                                    </div>
                                  ) : (
                                    <div></div>
                                  )}
                                </div>
                              ) : (
                                <div>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: room.description,
                                    }}
                                  />
                                  {room !== undefined &&
                                  room.CIN_code !== null &&
                                  room.CIN_code !== "" ? (
                                    <div style={{ marginTop: "8px" }}>
                                      <div>
                                        <b>
                                          {GetLabel(props.language, "CIN_CODE")}
                                        </b>{" "}
                                        {room.CIN_code}
                                      </div>
                                    </div>
                                  ) : (
                                    <div></div>
                                  )}
                                </div>
                              )
                            ) : (
                              <></>
                            )}
                          </Typography>
                          <div>
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap", // Aggiunto per consentire il wrapping dei servizi
                                marginLeft: { sm: marginLeftSm, xs: "0" },
                                whiteSpace: "pre-wrap", // Cambiato per il wrapping del testo
                                overflowX: "hidden", // Impedisce lo scroll orizzontale
                                marginRight: "5px",
                                marginTop: "10px",
                              }}
                              className="ios-scroll"
                            >
                              {room.services.length > 0 ? (
                                room.services.map((service, i) => {
                                  const isLastService =
                                    i === room.services.length - 1; // Controlla se è l'ultimo servizio
                                  return (
                                    <div
                                      key={i} // Aggiunto un `key` unico
                                      style={{
                                        marginRight: "10px",
                                        marginTop: "3px",
                                        marginBottom: "3px",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={service.icon}
                                        className="service-icon"
                                        style={{ width: "20px" }}
                                      />
                                      <span
                                        className="service-icon-text"
                                        style={{
                                          marginTop:
                                            service.icon === null ? "25px" : "",
                                        }}
                                      >
                                        {service.name}
                                        {!isLastService && " "}{" "}
                                      </span>
                                    </div>
                                  );
                                })
                              ) : (
                                <p
                                  style={{
                                    marginRight: "10px",
                                    marginTop: "3px",
                                    marginBottom: "3px",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={"fa-times"}
                                    className="service-icon"
                                  />
                                  <span className="service-icon-text">
                                    {GetLabel(props.language, "NO_SERVICES")}
                                  </span>
                                </p>
                              )}
                            </Box>
                          </div>
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap", // Aggiunto per consentire il wrapping dei servizi
                              marginLeft: { sm: marginLeftSm, xs: "0" },
                              whiteSpace: "pre-wrap", // Cambiato per il wrapping del testo
                              overflowX: "hidden", // Impedisce lo scroll orizzontale
                              marginRight: "5px",
                              marginTop: "10px",
                            }}
                            className="ios-scroll"
                          >
                            <a
                              variant="contained"
                              className="seeMore"
                              onClick={(e) => ShowRoomDetails(e, room)}
                              sx={{
                                borderRadius: "5px",
                                width: { sm: "auto", xs: "100%" },
                                fontWeight: "600",
                              }}
                            >
                              {GetLabel(props.language, "LABEL_SHOW_MORE")}
                            </a>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      sm={3}
                      xs={12}
                      className="room-price"
                      style={{
                        position: "relative",
                        paddingTop:
                          room.isNotRefundable && !isMobile ? "63px" : "20px",
                      }}
                    >
                      <Box className="main">
                        <p>{GetLabel(props.language, "PRICE_FROM")}</p>
                        {room.striked_price !== 0 ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                position: "relative",
                              }}
                            >
                              <span
                                class="selected_room_price booking-price-discount"
                                style={{
                                  fontSize: "16px !important",
                                  color: "#aaabad",
                                  fontWeight: "bolder",
                                  textDecoration: "line-through",
                                }}
                              >
                                € {parseFloat(room.striked_price).toFixed(2)}
                              </span>

                              <div
                                className="flex flex-row items-center absolute"
                                style={{ right: "-30px", top: "15px" }}
                              >
                                <DynamicHeroIcon
                                  icon="TagIcon"
                                  style={{
                                    textShadow:
                                      "1px 0 1px #dc3522 -1px 0 1px #dc3522",
                                    color: "red",
                                    width: "24px",
                                    height: "24px",
                                    position: "absolute",
                                    right: 0,
                                  }}
                                ></DynamicHeroIcon>
                                <span class="tag-discount__label">
                                  -{room.discount_perc}%
                                </span>
                              </div>
                            </div>
                            <h1 className="discount-price">
                              {parseFloat(room.price).toFixed(2)} €
                            </h1>
                          </>
                        ) : (
                          <h1 style={{ fontSize: "25px", fontWeight: "600" }}>
                            <div>{parseFloat(room.price).toFixed(2)} €</div>
                          </h1>
                        )}

                        <div className="flex flex-col gap-2">
                          {/* <Button
                            variant="contained"
                            className="booknow"
                            onClick={(e) => ShowRoomDetails(e, room)}
                            sx={{
                              borderRadius: "5px",
                              width: { sm: "auto", xs: "100%" },
                              fontWeight: "600",
                            }}
                          >
                            {GetLabel(props.language, "BUTTON_ROOM_INFO_BOOK")}
                            <MdKeyboardArrowDown style={{ fontSize: "15px" }} />
                          </Button> */}
                          <Button
                            variant="contained"
                            className="booknow"
                            onClick={() => handleButtonClick(room.price)}
                            // onClick={() => {
                            //   setOpenNotRefundable(!openNotRefundable);
                            //   setPriceRoom(room.price);
                            // }}
                            sx={{
                              borderRadius: "5px",
                              width: { sm: "auto", xs: "100%" },
                              fontWeight: "600",
                            }}
                          >
                            {GetLabel(props.language, "BUTTON_ROOM_INFO_BOOK")}
                            <MdKeyboardArrowDown style={{ fontSize: "15px" }} />
                          </Button>
                          {/* {room.isNotRefundable && (
                            <Button
                              variant="contained"
                              class="booknow"
                              onClick={() => {
                                setOpenNotRefundable(!openNotRefundable);
                                setPriceRoom(room.price);
                              }}
                              sx={{
                                borderRadius: "5px",
                                width: { sm: "auto", xs: "100%" },
                                fontWeight: "600",
                              }}
                            >
                              {GetLabel(
                                props.language,
                                "BUTTON_ROOM_INFO_BOOK"
                              )}
                              <MdKeyboardArrowDown
                                style={{ fontSize: "15px" }}
                              />
                              {GetLabel(props.language, "BUTTON_NOREFUNDABLE")}
                            </Button>
                          )} */}
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                </TabPanel>
              );
            })}

            <RoomOptions
              open={openNotRefundable}
              language={props.language}
              uuid={props.uuid}
              idCategory={props.idCategory}
              priceRoom={priceRoom}
              setPriceRoom={setPriceRoom}
              rooms={props.rooms}
              addCart={props.addCart}
              setOpen={setShowRoomDetails}
            />
          </animated.div>
        </>
      )}
    </>
  );
}
